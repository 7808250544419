/**
 * Gets the repositories of the user from Github
 */

import {
    debounce,
    call,
    put,
    select,
    takeEvery,
    throttle,
    delay,
    all,
} from "redux-saga/effects";
import { fromJS, Map, toArray } from "immutable";
import {
    updateToastDone,
    sendErrorToast,
    sendToast,
} from "../../utils/toaster";
import request from "../../utils/request";
import algoliasearch from "algoliasearch";
import {
    GET_USER_DETAILS,
    CREATE_NEW_TEST,
    EDIT_USERS,
    EDIT_TEAM_MEMBER,
    DELETE_USER,
    DEACTIVATE_USER,
    ADD_SLACK_INFO,
    CHANGE_PERMISSION,
    EDIT_API_KEYS,
    UPLOAD_INTERVIEW_DATA,
    GET_INTERVIEWS_DATA,
    RESEND_TEAM_INVITE,
    ADD_COMPANY,
    SWITCH_PLANS,
    GET_QUESTIONS_DATA,
    UPDATE_CARD,
    PUBLISH_UPDATE_TEST,
    ERROR_AUDIT_LOGS,
    GET_INTERVIEWS_AND_QUESTIONS_DATA,
    GET_SCORECARD_DATA,
    MODES,
    SEND_DISCORD_MESSAGE,
    SEND_REMINDER_EMAIL,
    UPDATE_TEST_SUBSCRIBERS,
    UPDATE_TEST_THRESHOLD,
    COMPILE_CODE,
    GET_SUBSCRIPTION_INVOICES,
    UPDATE_USER_ACTIVITY,
    UPDATE_TEST_TIMEBOXED,
    GET_INSIGHTS,
    REVIEW_INTERVIEW,
    END_INTERVIEW,
    DELETE_INTERVIEW,
    CANCEL_INTERVIEW,
    UPDATE_TEST_PROCTORING_MODE,
    UPDATE_TEST_ONELINK_MODE,
    GENERATE_TEST_ONELINK,
    TOGGLE_TEST_ONELINK,
    GET_TEST_LIBRARY,
    SEND_REJECTION_EMAIL,
    SEND_SHORTLIST_EMAIL,
    RESEND_CANDIDATE_INVITE_EMAIL,
    SEARCH_TEST_LIBRARY,
    ADD_NEW_TEST_REQUEST,
    ADD_UPDATE_EMAIL_TEMPLATE,
    UPDATE_TEST_EMAIL_TEMPLATES,
    TEST_SUCCESS_FACTORS,
    ADD_API_KEY,
    ADD_INTEGRATION_INFO,
    GET_AUDIT_LOGS,
    REMOVE_SAMPLE_CANDIDATES,
    REMOVE_QUESTION_FROM_SCORECARD,
    LOGOUT_USER,
    GET_PAIRS_PAGINATED,
    CREATE_PAIR,
    SEND_PAIR_INVITE_EMAIL,
    REGISTER_CANDIDATE_PAIR_DETAILS,
    GET_INTERVIEW_QUESTIONS,
    CREATE_INTERVIEW_QUESTION,
    ARCHIVE_INTERVIEW_QUESTION,
    CREATE_READY_TEST,
    GET_PUBLIC_QUESTIONS,
    FETCH_ONBOARDING_DATA,
    UPDATE_TEST_NAME,
    SEND_BULK_INVITES,
    EXPORT_GRAPH_FETCH,
    ADD_QUESTIONS_TO_TEST,
    DELETE_QUESTION_TEST,
    FETCH_RTG_DATA,
    GET_TEST_METADATA,
    SORT_QUESTIONS_DND,
    DELETE_CARD_DETAILS,
    CANCEL_SUBSCRIPTION,
    FETCH_SAAS_PLANS,
    DELETE_TEST,
    GET_SCORECARD_IP_DETAILS,
    GET_SCORECARD_INTERVIEW_DATA,
    GET_CANDIDATE_FUNNEL,
    GET_SCORE_DISTRIBUTION,
    GET_DAYS_DATA,
    GET_INVITED_BY,
    GET_AVG_COMPLETION_TIME,
    GET_AVG_RATINGS,
    GET_REVIEWS,
    GET_SHALLOW_INTERVIEWS,
    GET_HIGH_LEVEL_INTERVIEWS_TOTAL,
    GET_INTERVIEW_DETAILS,
    PREVIEW_ASSESSMENT,
    GET_PREVIEW_TEST,
    GET_SCORE_TIME_SERIES,
    UPDATE_TEST_METADATA,
    FETCH_ADDRESS,
    FETCH_LIVE_UPDATES,
    FETCH_PROBLEMS,
    SAVE_PROBLEM,
    FETCH_SUBMISSIONS,
    VERIFY_QUESTION,
    UPDATE_CREDITS_MANUALLY,
    UPDATE_QUESTION_FIELD,
    REVIEW_BULK_INTERVIEWS,
    CANCEL_BULK_INTERVIEWS,
    SEND_BULK_RESULT_EMAIL_TO_CANDIDATE,
    FETCH_NOTIFICATION_FEED,
    TRIGGER_KNOCK_NOTIFICATION,
    GET_QUESTIONS_DATA_TEST,
    GET_ADDITIONAL_TESTS_DATA,
    UPDATE_FAQS,
    FETCH_ADA_AI_CONTACTS,
    FETCH_ADA_AI_CONTACT_DOCUMENTS,
    FETCH_ADA_AI_CONTACT_SESSIONS,
    CREATE_ADA_AI_QUERY,
    UPSERT_DOCUMENTS,
    ADD_OR_UPDATE_LISTMONK,
    GET_ALL_CATEGORIES,
    COMPLETELY_DELETE_USER,
    UPDATE_BILLING_DETAILS,
    GET_SELECT_REVIEWS,
    GET_BLOCKED_EMAILS,
    DELETE_BLOCKED_EMAIL,
    SEND_CUSTOM_TEST_READY_EMAIL,
    EXTEND_INTERVIEW_DEADLINE,
    CLONE_TEST,
    CREATE_BLANK_TEST,
    GET_QUESTION_LIBRARY,
    ARCHIVE_QUESTION,
    EDIT_EMAIL_TEMPLATE,
    RESET_LEVER_INTEGRATION,
    SAVE_QUESTIONS_TO_TEST_FROM_LIBRARY,
} from "./constants";
import {
    getSelectReviewsSuccess,
    getTestMetadataSuccess,
    deleteQuestionFromTestResult,
    addQuestionsToTestResult,
    sortQuestionsDnDResult,
    sendBulkInvitesSuccess,
    getReadyAssessmentSuccess,
    getPublicQuestionsSuccess,
    getUserDetailsSuccess,
    interviewsDataSuccess,
    getScorecardDataSuccess,
    updateCardStatus,
    getQuestionsSuccess,
    getInsightsSuccess,
    resetTest,
    changeCurrentMode,
    updateTestSubscribersLoader,
    updateTestMetadataLoader,
    compileCodeSuccess,
    getTestLibrarySuccess,
    searchTestLibrarySuccess,
    auditLogsSuccess,
    getSubscriptionInvoicesSuccess,
    uploadInterviewDataResult,
    addMultiAuthDetail,
    fetchCareersSuccess,
    getPairsDataSuccess,
    getInterviewQuestionsSucess,
    fetchOnboardingDataSuccess,
    exportGraphFetchSuccess,
    fetchRtgDataSuccess,
    deleteCardDetailsSuccess,
    cancelSubscriptionSuccess,
    fetchSaasPlansSuccess,
    deleteTestStatus,
    getScorecardIpDetailsSuccess,
    getScorecardInterviewDataSuccess,
    getCandidateFunnelSuccess,
    getScoreDistributionSuccess,
    getDaysDataSuccess,
    getInvitedBySuccess,
    getAvgCompletionTimeSuccess,
    getAvgRatingsSuccess,
    getReviewsSuccess,
    deleteInterviewSuccess,
    getShallowInterviewsDataSuccess,
    getHighLevelInterviewsTotalSuccess,
    getInterviewDetailsSuccess,
    cancelInterviewSuccess,
    endInterviewSuccess,
    previewAssessmentSuccess,
    getPreviewTestSuccess,
    addNewTestRequestSuccess,
    changeNewTestNameStatus,
    getScoreTimeSeriesSuccess,
    generateTestOnelinkSuccess,
    fetchAddressSuccess,
    fetchLiveUpdateSuccess,
    fetchProblemsSuccess,
    saveProblemSuccess,
    saveProblemFailure,
    fetchSubmissionsSuccess,
    updateCreditsManuallyStatus,
    updateQuestionFieldStatus,
    reviewBulkInterviewsStatus,
    cancelBulkInterviewsStatus,
    sendBulkResultEmailToCandidateStatus,
    customizeQuestionsToTestResult,
    verifyQuestionSuccess,
    fetchNotificationFeedStatus,
    getQuestionsTestSuccess,
    getAdditionalTestsDataSuccess,
    updateFAQsStatus,
    fetchAdaAIContactsStatus,
    fetchAdaAIContactDocumentsStatus,
    fetchAdaAIContactSessionsStatus,
    createAdaAIQueryStatus,
    reviewInterviewSuccess,
    uploadInterviewDataSuccess,
    getAllCategoriesSuccess,
    updateBillingDetailsSuccess,
    updateBillingDetailsError,
    getBlockedEmailsSuccess,
    deleteBlockedEmailSuccess,
    extendInterviewDeadlineSuccess,
    extendInterviewDeadlineFailure,
    cloneTestStatus,
    createBlankTestStatus,
    archiveQuestionStatus,
    editEmailTemplateStatus,
    saveQuestionsToTestFromLibraryStatus,
} from "./actions";

import getAPIDomain from "../../utils/domain";

import { sendDiscordAlert } from "../../shared/discord";
import { upsertDocumentsStatus } from "./actions";
import { send } from "process";
import { fetchCachedAPI } from "../../utils/cachedRequest";
import moment from "moment";

const searchClient = algoliasearch(
    "FIP8IMQZLD",
    "54de44e468c9a8f5d822ee16d0157635"
);
const search_tests = searchClient.initIndex("tests");
const parserDomain =
    window.location.href.indexOf("localhost") > -1
        ? "http://localhost:3250"
        : window.location.href.indexOf("tester") > -1
          ? "https://ai.adaface.com"
          : "https://ai.adaface.com";
const flowDomain =
    window.location.href.indexOf("localhost") > -1
        ? "http://localhost:3600"
        : window.location.href.indexOf("tester") > -1
          ? "https://test-flow.adaface.com"
          : "https://flow.adaface.com";

function getQueryStringValue(key) {
    return decodeURIComponent(
        window.location.search.replace(
            new RegExp(
                "^(?:.*[&\\?]" +
                    encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") +
                    "(?:\\=([^&]*))?)?.*$",
                "i"
            ),
            "$1"
        )
    );
}

function* fetchSaasPlans({ planId, pairPlanId }) {
    try {
        const { success, data, error } = yield call(() =>
            request(getAPIDomain() + "/app/api/internal/get_saas_plans", {
                method: "POST",
                body: JSON.stringify({
                    planId,
                    pairPlanId,
                }),
            })
        );
        if (success) {
            yield put(fetchSaasPlansSuccess({ data }));
        }
    } catch (err) {
        console.log("ERRROORR", err);
    }
}

function* fetchRTGData() {
    const data = yield call(() =>
        fetchCachedAPI({
            url: getAPIDomain() + "/app/api/internal/list_ready_to_go_tests",
            etagName: "rtgTestsETag",
            etagDataName: "rtgTests",
        })
    );

    yield put(fetchRtgDataSuccess({ data }));
}

function* getAdditionalTestsData({ companyId }) {
    let additionalTests = [];
    try {
        additionalTests = yield call(() =>
            request(
                getAPIDomain() + "/app/api/internal/list_additional_tests",
                {
                    method: "POST",
                    body: JSON.stringify({
                        companyId,
                    }),
                }
            )
        );
        yield put(getAdditionalTestsDataSuccess({ additionalTests }));
    } catch (err) {}
}

function* getTestLibrary({ env, categoryId }) {
    let data = {
        tests: [],
        categories: [],
    };

    const tests = yield call(() =>
        fetchCachedAPI({
            url: getAPIDomain() + "/app/api/internal/list_all_tests",
            etagName: "testLibraryETag",
            etagDataName: "tests",
        })
    );

    const categories = yield call(() =>
        fetchCachedAPI({
            url: getAPIDomain() + "/app/api/internal/list_all_test_categories",
            etagName: "testLibraryCategoriesETag",
            etagDataName: "categories",
            defaultData: [],
        })
    );

    categories.map(({ group, categories }) => {
        categories.map((categoryDeets) => {
            const { category, tests: testUrls } = categoryDeets;
            categoryDeets.testsWithActualData = testUrls
                .map((url) => {
                    if (url && url.trim()) {
                        var test = tests[url];
                        if (test) {
                            return test;
                        }
                    }
                })
                .filter((x) => x);
        });
    });

    yield put(
        getTestLibrarySuccess({
            categories,
            tests,
            categoryId,
            testsMap: tests,
        })
    );
}

function* getTestMetadata({ testId }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + `/app/api/internal/test_metadata`, {
            method: "POST",
            body: JSON.stringify({
                testId,
            }),
        })
    );

    yield put(
        getTestMetadataSuccess({
            testId,
            data,
        })
    );
    NProgress && NProgress.done();
}

function* getPublicQuestions({ categories, testId }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + `/app/api/internal/list_public_questions`, {
            method: "POST",
            body: JSON.stringify({
                testId,
                categories,
            }),
        })
    );

    Object.keys(data.allSkills).map((x) => {
        data.allSkills[data.allSkills[x]] = x;
    });
    Object.keys(data.allCategories).map((x) => {
        data.allCategories[data.allCategories[x]] = x;
    });

    yield put(
        getPublicQuestionsSuccess({
            testId,
            questions: data.questions,
            allSkills: data.allSkills,
            allCategories: data.allCategories,
        })
    );
    NProgress && NProgress.done();
}

function* getInterviewQuestions({
    env,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/integrations/dashboard/list_interview_questions`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    limit,
                    pageNo,
                    sortBy,
                    filters,
                }),
            }
        )
    );

    yield put(
        getInterviewQuestionsSucess({
            companyId,
            questions: data.questions,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    NProgress && NProgress.done();
}

function* getInsights({
    companyId,
    testId,
    includeAllRatings,
    startDate,
    endDate,
}) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/integrations/dashboard/insights", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                testId,
                startDate,
                endDate,
                includeAllRatings,
            }),
        })
    );

    yield put(
        getInsightsSuccess({
            data: data.insights,
            testId,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
    NProgress && NProgress.done();
}

function* getCandidateFunnel({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/candidate_funnel",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    startDate,
                    endDate,
                }),
            }
        )
    );

    yield put(
        getCandidateFunnelSuccess({
            data: (data || {}).candidateFunnel || {},
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getScoreDistribution({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/score_distribution",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    startDate,
                    endDate,
                }),
            }
        )
    );

    yield put(
        getScoreDistributionSuccess({
            data: (data || {}).scoreDistribution || {},
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getDaysData({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/integrations/dashboard/days_data", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                testId,
                startDate,
                endDate,
            }),
        })
    );

    yield put(
        getDaysDataSuccess({
            data: (data || {}).tieredCompletionDaysCount || {},
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getInvitedBy({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/integrations/dashboard/invited_by", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                testId,
                startDate,
                endDate,
            }),
        })
    );

    yield put(
        getInvitedBySuccess({
            data: (data || {}).tieredInvitedBy || {},
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getAvgCompletionTime({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/avg_completion_time",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    startDate,
                    endDate,
                }),
            }
        )
    );

    yield put(
        getAvgCompletionTimeSuccess({
            data: (data || {}).averageTimeToComplete || 0,
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getAvgRatings({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/avg_ratings",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    startDate,
                    endDate,
                }),
            }
        )
    );

    yield put(
        getAvgRatingsSuccess({
            data: data || {},
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getReviews({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/integrations/dashboard/reviews", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                testId,
                startDate,
                endDate,
            }),
        })
    );

    yield put(
        getReviewsSuccess({
            data: data || {},
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* getScoreTimeSeries({ companyId, testId, startDate, endDate }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/score_time_series",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    startDate,
                    endDate,
                }),
            }
        )
    );

    const aggregateScoresByMonthYear = (dailyScores) => {
        // dailyScores is an array with each element
        // score, d, count. d is a string of format DD/MM/YYYY
        let aggregatedScores = {};

        // Loop through each score object and aggregate by month and year
        dailyScores.forEach((scoreObject) => {
            let parts = scoreObject.d.split("/");
            let date = new Date(parts[2], parts[1] - 1, parts[0]);

            // create YYYY-MM key MM should be the month number with 0 before if < 10
            let monthYearKey = `${date.getFullYear()}${
                date.getMonth() < 9 ? "0" : ""
            }${date.getMonth() + 1}`;

            if (!aggregatedScores[monthYearKey]) {
                aggregatedScores[monthYearKey] = {
                    score: 0,
                    count: 0,
                    score: 0,
                    firstDayOfMonth: new Date(
                        date.getFullYear(),
                        date.getMonth(),
                        1
                    ),
                    display: `${date.toLocaleString("default", {
                        month: "short",
                    })} ${date.getFullYear()}`, // Readable MM followed by year
                };
            }
            aggregatedScores[monthYearKey].score =
                (scoreObject.score * scoreObject.count +
                    aggregatedScores[monthYearKey].score *
                        aggregatedScores[monthYearKey].count) /
                (aggregatedScores[monthYearKey].count + scoreObject.count);
            aggregatedScores[monthYearKey].count += scoreObject.count;
        });

        // Convert the aggregated object into an array of scores
        let sortedKeys = Object.keys(aggregatedScores).sort(
            (a, b) => Number(a) - Number(b)
        );
        return sortedKeys.map((key) => {
            return {
                monthYear: key,
                ...(aggregatedScores[key] || {}),
            };
        });
    };

    const monthlyScores = aggregateScoresByMonthYear(data);

    yield put(
        getScoreTimeSeriesSuccess({
            data: monthlyScores,
            testId,
        })
    );
    NProgress && NProgress.done();
}

function* searchTestLibrary({ env, keyword, pageNo, userData }) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        search_tests.search(keyword, {
            attributesToRetrieve: [
                "name",
                "url",
                "primaryCategories",
                "secondaryCategories",
                "isRTG",
                "time",
                "mcqs",
                "coding",
                "numMCQs",
                "numCoding",
                "richQuestionsSummary",
                "redirectUrl",
            ],
            hitsPerPage: 50,
            page: pageNo ? pageNo : 0,
            clickAnalytics: true,
            removeWordsIfNoResults: "allOptional",
        })
    );

    yield put(
        searchTestLibrarySuccess({
            keyword,
            tests: data,
            env,
        })
    );

    if ((data.hits || []).length == 0) {
        // show error toast that there are no tests in the library found for this keyword
        sendErrorToast("No tests found for this keyword.");

        yield call(() =>
            sendDiscordMessage({
                message: `No tests found for this keyword: ${keyword} on dashboard`,
                channel: "logs-adaface-alerts",
                data: {
                    keyword,
                    ...userData,
                },
            })
        );
    }

    NProgress && NProgress.done();
}

function* fetchUserDetails({ userId, env }) {
    NProgress && NProgress.start();

    const sendPreviewData =
        !window.PREVIEW_TEST_ID && getQueryStringValue("preview-test-id");

    const newUser = !window.NEW_USER && getQueryStringValue("newuser");

    window.PREVIEW_TEST_ID = true;
    window.NEW_USER = getQueryStringValue("newuser") ? true : false;

    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/company", {
            method: "POST",
            body: JSON.stringify({
                companyId: userId,
                previewTestId: sendPreviewData
                    ? getQueryStringValue("preview-test-id")
                    : null,
            }),
        })
    );

    if (sendPreviewData && !data.error && data.moveToPreviewOfTestId) {
        // TODO:(SG) Go to the created preview test
        const alert = yield call(() =>
            sendDiscordMessage({
                message: `Created preview test or moving to existing: ${JSON.stringify(
                    {
                        env,
                        baseTestId: getQueryStringValue("preview-test-id"),
                        companyId: userId,
                        testId: data.moveToPreviewOfTestId,
                    },
                    null,
                    4
                )}`,
                channel: "logs-preview-test",
            })
        );
    }

    if (newUser && !data.error) {
        if (typeof gtag !== "undefined" && gtag) {
            gtag("event", "ab_split_test", {
                test_event: "signup_success",
            });
        }
    }

    yield put(
        getUserDetailsSuccess({
            env,
            userId,
            data,
        })
    );

    NProgress && NProgress.done(true);
}

function* createReadyTest({
    env,
    testId,
    companyId,
    requesterEmailId,
    requesterName,
    skills,
    coding,
}) {
    NProgress && NProgress.start();

    let updatedTestId;
    let newQuestions = {};

    try {
        const data = yield call(() =>
            request(getAPIDomain() + "/app/api/company/ready-test/create", {
                method: "POST",
                body: JSON.stringify({
                    env,
                    testId,
                    companyId,
                    requesterEmailId,
                    skills,
                    coding,
                    requesterName,
                }),
            })
        );
        updatedTestId = data.testId;
        (data.questions || []).map((q) => {
            newQuestions[q.questionId] = q;
        });
    } catch (err) {
        console.log("error", err);
    }

    // TODO: this is not optimized. but will work for now
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        getReadyAssessmentSuccess({
            testId: updatedTestId,
            questions: newQuestions,
        })
    );

    NProgress && NProgress.done(true);
}

function* createBlankTest({ env, companyId, displayName }) {
    let updatedTestId = null;
    try {
        yield put(
            createBlankTestStatus({
                status: "in-progress",
            })
        );

        const data = yield call(() =>
            request(getAPIDomain() + "/app/api/company/test/create", {
                method: "POST",
                body: JSON.stringify({
                    env,
                    questions: [],
                    testId: null,
                    roleId: null,
                    displayName,
                    companyId,
                    dtreeKey: null,
                    userId: null,
                    threshold: 55,
                    maxTimeInMinutes: 5,
                    dtreesDetails: [],
                    subscribers: [],
                    timeboxed: true,
                    proctoringMode: true,
                    requestId: false,
                }),
            })
        );
        updatedTestId = data.testId;
    } catch (err) {
        console.log("error", err);

        yield put(
            createBlankTestStatus({
                status: "failed",
            })
        );
    }

    if (updatedTestId) {
        // TODO: this is not optimized. but will work for now
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        yield put(
            createBlankTestStatus({
                status: "success",
            })
        );

        sendToast(`Created blank test ${displayName} successfully`);

        yield put(
            changeCurrentMode({
                mode: MODES.SAVED_TESTS,
                currentModeMetadata: updatedTestId,
            })
        );

        window.history.pushState(
            {},
            "",
            `/app/dashboard/${companyId}/assessment/${updatedTestId}/questions`
        );
    } else {
        sendErrorToast("Error creating blank test");
    }
}

function* cloneTest({ env, companyId, testId }) {
    let updatedTestId = null;
    try {
        yield put(
            cloneTestStatus({
                status: "in-progress",
            })
        );

        const { test, error } = yield call(() =>
            request(getAPIDomain() + "/app/api/company/test/clone", {
                method: "POST",
                body: JSON.stringify({
                    env,
                    testId,
                    companyId,
                }),
            })
        );

        if (error) {
            throw new Error(error);
        }

        updatedTestId = test?.testId;

        // TODO: this is not optimized. but will work for now
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        yield put(cloneTestStatus({ status: "success" }));

        sendToast(`Cloned test successfully`);

        yield put(
            changeCurrentMode({
                mode: MODES.SAVED_TESTS,
                currentModeMetadata: updatedTestId,
            })
        );

        window.history.pushState(
            {},
            "",
            `/app/dashboard/${companyId}/assessment/${updatedTestId}/questions`
        );
    } catch (err) {
        yield put(cloneTestStatus({ status: "failed" }));

        console.log("error", err);

        sendErrorToast("Error cloning test");
    }
}

function* publishUpdateTest({
    env,
    questions,
    testId,
    displayName,
    companyId,
    userId,
    dtreesDetails,
    threshold,
    timeboxed,
    maxTimeInMinutes,
    subscribers,
    proctoringMode,
    requestId,
}) {
    // console.log("am here");
    let updatedTestId = null;

    try {
        const data = yield call(() =>
            request(getAPIDomain() + "/app/api/company/test/create", {
                method: "POST",
                body: JSON.stringify({
                    env,
                    questions,
                    testId,
                    displayName,
                    companyId,
                    dtreesDetails,
                    threshold,
                    timeboxed,
                    maxTimeInMinutes,
                    subscribers,
                    proctoringMode,
                    requestId,
                }),
            })
        );
        updatedTestId = data.testId;
        yield put(
            addMultiAuthDetail({
                authData: data.authData,
            })
        );
    } catch (err) {
        console.log("error", err);
    }

    // Update toast
    updateToastDone(
        testId ? `Updated test ${displayName}` : `Created test ${displayName}`,
        PUBLISH_UPDATE_TEST
    );

    // TODO: this is not optimized. but will work for now
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId,
        })
    );

    // Reset test builder
    yield put(resetTest());

    // Switch to test view
    yield put(
        changeCurrentMode({
            mode: MODES.SAVED_TESTS,
            currentModeMetadata: testId || updatedTestId,
        })
    );
}

function* resendTeamInvite({ env, companyId, email }) {
    NProgress && NProgress.start();
    const check = yield call(() =>
        request(getAPIDomain() + "/app/api/user/company/resend_invite", {
            method: "POST",
            body: JSON.stringify({
                email,
                env,
                companyId,
            }),
        })
    );

    sendToast(`Resent email invite to ${email}!`);

    yield put(
        addMultiAuthDetail({
            authData: check.authData,
        })
    );
    NProgress && NProgress.done(true);
}

function* editTeamMember({
    email,
    tests,
    companyId,
    permission,
    team,
    name,
    env,
}) {
    NProgress && NProgress.start();

    const check = yield call(() =>
        request(getAPIDomain() + "/app/api/user/company/edit_team_member", {
            method: "POST",
            body: JSON.stringify({
                email,
                tests,
                permission,
                team,
                name,
                companyId,
            }),
        })
    );

    if (check?.error) {
        sendErrorToast(check?.error);
    } else {
        // TODO: this is not optimized. but will work for now
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        sendToast(`Updated team member details successfully!`);
    }

    NProgress && NProgress.done(true);
}

function* editUsers({
    env,
    companyId,
    userEmailId,
    name,
    role,
    invitingName,
    invitingEmailId,
    permissions,
    teamSize,
    sampleTests,
    restrictions,
}) {
    NProgress && NProgress.start();

    if (teamSize <= 50) {
        const check = yield call(() =>
            request(getAPIDomain() + "/app/api/user/company/invite", {
                method: "POST",
                body: JSON.stringify({
                    emailId: userEmailId,
                    name,
                    env,
                    companyId,
                    role,
                    invitingName,
                    invitingEmailId,
                    permissions,
                    restrictions,
                }),
            })
        );

        if (check?.error) {
            sendErrorToast(check?.error);
        } else {
            // TODO: this is not optimized. but will work for now
            const rand = yield call(() =>
                fetchUserDetails({
                    env,
                    userId: companyId,
                })
            );

            const restrictedTests = restrictions?.tests || [];

            // loop through sample tests. for each test, add the user to the test subscribers
            for (let i = 0; i < sampleTests.length; i++) {
                if (
                    restrictedTests.length === 0 ||
                    restrictedTests.includes(sampleTests[i].testId)
                ) {
                    yield call(() =>
                        updateTestSubscribers({
                            env,
                            companyId,
                            testId: sampleTests[i].testId,
                            emailId: sampleTests[i].testId,
                            subscribers: [
                                ...(sampleTests[i].subscribers || []),
                                userEmailId,
                            ],
                            sub: true,
                        })
                    );
                }
            }

            sendToast(
                `Invite sent ${name ? "to " + name + " " : ""}over email.`
            );
        }
    } else {
        sendErrorToast(
            "Error inviting teammate, please ping our support team on chat"
        );
    }
    NProgress && NProgress.done(true);
}

function* completelyDeleteUser({ env, companyId, emailId }) {
    NProgress && NProgress.start();

    const results = yield call(() =>
        request(
            getAPIDomain() + "/app/api/company_users/completely_delete_user",
            {
                method: "POST",
                body: JSON.stringify({
                    emailId,
                    env,
                    companyId,
                }),
            }
        )
    );

    if (!results.error) {
        // TODO: this is not optimized. but will work for now
        sendToast(`Completely deleted user ${emailId}!`);
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );
    } else {
        sendErrorToast(
            results.error || "There is an error with deleting the user"
        );
    }

    NProgress && NProgress.done(true);
}

function* deleteUser({ env, companyId, email }) {
    NProgress && NProgress.start();

    const check = yield call(() =>
        request(getAPIDomain() + "/app/api/user/company/delete", {
            method: "POST",
            body: JSON.stringify({
                email,
                env,
                companyId,
            }),
        })
    );

    // TODO: this is not optimized. but will work for now
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: check.authData,
        })
    );
    NProgress && NProgress.done(true);
}

function* deactivateUser({ env, companyId, email, deactivate }) {
    NProgress && NProgress.start();

    const check = yield call(() =>
        request(getAPIDomain() + "/app/api/user/company/deactivate", {
            method: "POST",
            body: JSON.stringify({
                email,
                env,
                companyId,
                deactivate,
            }),
        })
    );

    // TODO: this is not optimized. but will work for now
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: check.authData,
        })
    );
    NProgress && NProgress.done(true);
}

function* changePermissions({ env, companyId, email, permissions }) {
    NProgress && NProgress.start();

    const check = yield call(() =>
        request(getAPIDomain() + "/app/api/user/company/change_permissions", {
            method: "POST",
            body: JSON.stringify({
                email,
                env,
                companyId,
                permissions,
            }),
        })
    );

    // TODO: this is not optimized. but will work for now
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: check.authData,
        })
    );

    NProgress && NProgress.done(true);
}

function* deleteQuestionFromTest({ companyId, testId, questionId }) {
    NProgress && NProgress.start();
    let data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/delete_question_test",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    questionId,
                }),
            }
        )
    );

    if (data.success) {
        sendToast("Deleted question");
    } else {
        sendErrorToast(
            data.error || "There is an error with deleting the question"
        );
    }

    const { success, error, test } = data;

    yield put(
        deleteQuestionFromTestResult({
            companyId,
            testId,
            success,
            error,
            test,
        })
    );
    NProgress && NProgress.done(true);
}

function* addQuestionsToTest({
    env,
    via,
    companyId,
    testId,
    questions,
    mode,
    alertDiff,
}) {
    NProgress && NProgress.start();
    let data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/upload_add_question_test",
            {
                method: "POST",
                body: JSON.stringify({
                    via,
                    companyId,
                    ...(mode === "customize" ? { testId } : {}),
                    questions,
                    mode,
                    alertDiff,
                }),
            }
        )
    );

    if (data.success) {
        if (mode) {
            sendToast("Modified question successfully");
        } else {
            sendToast(
                `Added question${questions.length > 1 ? "s" : ""} successfully`
            );
        }
    } else {
        if (mode == "edit") {
            sendErrorToast("Failed to edit the question");
        } else if (mode == "customize") {
            sendToast("Failed to customize the question");
        } else {
            sendErrorToast("Failed to add the question");
        }
    }

    const { success, error, questions: newQuestions, test: updatedTest } = data;

    if (mode === "customize") {
        yield put(
            customizeQuestionsToTestResult({
                companyId,
                testId,
                success,
                error: error || "",
                updatedTest,
            })
        );
    } else {
        yield put(
            addQuestionsToTestResult({
                companyId,
                testId: mode ? testId : null,
                success,
                error: error || "",
                newQuestions,
                updatedTest,
            })
        );
    }
    NProgress && NProgress.done(true);
}

function* verifyQuestion({ questionId, field, value, companyId }) {
    NProgress && NProgress.start();
    let data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/verify_question",
            {
                method: "POST",
                body: JSON.stringify({
                    questionId,
                    field,
                    value,
                    companyId,
                }),
            }
        )
    );

    if (data && data.success && data.question && data.question.questionId) {
        let newQuestions = {};
        newQuestions[data.question.questionId] = data.question;
        yield put(verifyQuestionSuccess({ newQuestions }));
    }

    NProgress && NProgress.done(true);
}

function* sortQuestionsDnd({
    env,
    companyId,
    testId,
    questions,
    dtreesDetails,
}) {
    NProgress && NProgress.start();
    let data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/sort_questions_dnd",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    questions,
                    dtreesDetails,
                }),
            }
        )
    );

    if (data.success) {
        sendToast("Re-ordered questions successfully");
    } else {
        sendErrorToast(data.error || "Unable to re-order to current place");
    }

    const { success, error } = data;

    yield put(
        sortQuestionsDnDResult({
            companyId,
            testId,
            success,
            error,
        })
    );
    NProgress && NProgress.done(true);
}

function* exportGraphFetch({
    companyId,
    testId,
    startDate,
    endDate,
    timeOffsetInSeconds,
}) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/export-graph",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    startDate,
                    endDate,
                    timeOffsetInSeconds,
                }),
            }
        )
    );

    if (data) {
        if (data.length > 0) {
            const fields = data[0];
            data = data
                .filter((v, i) => i > 0)
                .map((x) => {
                    let m = {};
                    fields.map((f, i) => {
                        m[f] = x.length > i ? x[i] : null;
                    });
                    return m;
                });
        }
    }

    yield put(exportGraphFetchSuccess({ data }));

    NProgress && NProgress.done(true);
}

function* sendBulkInvites({
    env,
    companyId,
    emails,
    names,
    testId,
    testName,
    requesterName,
    requesterEmailId,
    questionsSummary,
    interviewScheduledTime,
    interviewExpiryTime,
    recruiterTimeZone,
    campaign,
    replyTo,
    view,
}) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/upload_test_bulk",
            {
                method: "POST",
                body: JSON.stringify({
                    env,
                    companyId,
                    testId,
                    candidates: emails.map((x) => ({ email: x })),
                    names,
                    requesterName,
                    requesterEmailId,
                    informCandidate: true,
                    questionsSummary,
                    interviewScheduledTime,
                    interviewExpiryTime,
                    recruiterTimeZone,
                    campaign,
                    replyTo,
                    invitedVia: "dashboard",
                }),
            }
        )
    );

    if ((data || {}).success) {
        sendToast(
            `Successfully sent invites to ${data.data.candidates.length} candidates`
        );
        const notify = yield call(() =>
            triggerKnockNotification({
                companyId,
                action: "sent-bulk-invite",
                message: `${requesterEmailId} invited ${(emails || []).join(
                    ", "
                )} to ${testName}`,
                data: {
                    testName: testName,
                    interviewDetails: (data || {}).candidates,
                },
            })
        );
        const rand3 = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        if (view === "table") {
            const rand1 = yield call(() =>
                getShallowInterviewsData({
                    env,
                    companyId,
                    limit: 25,
                    pageNo: 1,
                    filters: [],
                    sortBy: "none",
                    view,
                })
            );
        }

        const rand2 = yield call(() =>
            getHighLevelInterviewsTotal({
                limit: 25,
                env,
                companyId,
                filters: [],
                sortBy: "none",
            })
        );
    } else {
        sendErrorToast(
            (data || {}).error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
    }

    yield put(
        sendBulkInvitesSuccess({
            success: true || (data || {}).success,
            data:
                (((data || {}).data || {}).candidates || []).map((x) => ({
                    candidateEmailId: x.email,
                    candidateName: x.name,
                    interviewId: x.interviewId,
                    testName,
                    testId,
                    interviewInviteSentTime: Date.now(),
                })) || [],
            view,
        })
    );

    // TODO: this is not optimized. but will work for now

    NProgress && NProgress.done(true);
}

function* uploadInterviewData({
    candidateEmailId,
    candidateName,
    companyId,
    roleId,
    interviewId,
    testId,
    testName,
    informCandidate,
    candidatePhoneNumber,
    env,
    limit,
    pageNo,
    requesterEmailId,
    requesterName,
    filters,
    sortBy,
    forceSend,
    questionsSummary,
    interviewScheduledTime,
    interviewExpiryTime,
    recruiterTimeZone,
    replyTo,
    view,
}) {
    try {
        const check = yield call(() =>
            request(
                getAPIDomain() + "/app/api/integrations/dashboard/upload_test",
                {
                    method: "POST",
                    body: JSON.stringify({
                        candidateEmailId,
                        roleId,
                        companyId,
                        candidateName,
                        candidatePhoneNumber,
                        interviewId,
                        testId,
                        informCandidate,
                        requesterEmailId,
                        requesterName,
                        questionsSummary,
                        forceSend,
                        interviewScheduledTime,
                        interviewExpiryTime,
                        recruiterTimeZone,
                        replyTo,
                    }),
                }
            )
        );

        if (!check.success && check.duplicateInvite) {
            sendErrorToast(
                `The candidate (${candidateEmailId}) already received an invite for this test.`
            );
        } else if (!(check || {}).success) {
            sendErrorToast(
                (check || {}).error ||
                    "Error sending email. Send email manually or reach out to us for support"
            );
        }

        if (check.success && !check.duplicateInvite) {
            sendToast(`Test invite sent to ${candidateEmailId}!`);

            const notify = yield call(() =>
                triggerKnockNotification({
                    companyId,
                    action: "sent-invite",
                    message: `${requesterEmailId} invited ${
                        candidateEmailId || candidateName
                    } to ${testName}`,
                    data: {
                        testName: testName,
                        interviewDetails: (check || {}).interviewId,
                    },
                })
            );
            if (view === "table") {
                const rand1 = yield call(() =>
                    getShallowInterviewsData({
                        env,
                        companyId,
                        limit,
                        pageNo,
                        filters,
                        sortBy,
                        view,
                    })
                );
                yield put(
                    uploadInterviewDataSuccess({
                        status: "success",
                        candidate: {
                            candidateEmailId,
                            candidateName,
                            interviewId: (check || {}).interviewId,
                            testName,
                            testId,
                            interviewInviteSentTime: Date.now(),
                        },
                        view: "kanban",
                    })
                );
            } else {
                const rand1 = yield call(() =>
                    getShallowInterviewsData({
                        env,
                        companyId,
                        limit,
                        pageNo,
                        filters,
                        sortBy,
                        view: "table",
                    })
                );
                yield put(
                    uploadInterviewDataSuccess({
                        status: "success",
                        candidate: {
                            candidateEmailId,
                            candidateName,
                            interviewId: (check || {}).interviewId,
                            testName,
                            testId,
                            interviewInviteSentTime: Date.now(),
                        },
                        view,
                    })
                );
            }
            const rand2 = yield call(() =>
                getHighLevelInterviewsTotal({
                    env,
                    companyId,
                    limit,
                    filters,
                    sortBy,
                })
            );

            const rand3 = yield call(() =>
                fetchUserDetails({
                    env,
                    userId: companyId,
                })
            );
        }

        yield put(
            uploadInterviewDataResult({
                candidateEmailId,
                candidateName,
                companyId,
                roleId,
                interviewId,
                testId,
                informCandidate,
                candidatePhoneNumber,
                env,
                limit,
                pageNo,
                requesterEmailId,
                requesterName,
                filters,
                sortBy,
                forceSend,
                result: check,
                questionsSummary,
                interviewScheduledTime,
                interviewExpiryTime,
                recruiterTimeZone,
                replyTo,
                view,
                testName,
            })
        );
    } catch (err) {
        const alert = yield call(() =>
            sendDiscordMessage({
                message: `Candidate invite did not go through! ${candidateEmailId}, ${companyId}, ${candidateName}, ${candidatePhoneNumber}, ${testId}`,
            })
        );
    }
}

function* getInterviewsAndQuestionsData({
    env,
    limit,
    pageNo,
    filters,
    sortBy,
    companyId,
}) {
    NProgress && NProgress.start();

    const run2 = yield call(() =>
        getInterviewsData({
            env,
            limit,
            pageNo,
            filters,
            sortBy,
            companyId,
        })
    );

    NProgress && NProgress.done(true);
}

function* getAuditLogs({ limit, pageNo, companyId, env }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + `/app/api/integrations/dashboard/audit_logs`, {
            method: "POST",
            body: JSON.stringify({
                companyId,
                limit,
                pageNo,
            }),
        })
    );

    yield put(
        auditLogsSuccess({
            companyId,
            data,
        })
    );

    NProgress && NProgress.done();
}

function* archiveInterviewQuestion({
    companyId,
    id,
    env,
    limit,
    pageNo,
    filters,
    sortBy,
}) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/integrations/dashboard/archive_interview_question`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    id,
                }),
            }
        )
    );

    const rand2 = yield call(() =>
        getInterviewQuestions({
            env,
            companyId,
            limit,
            pageNo,
            filters,
            sortBy,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    NProgress && NProgress.done();
}

function* createInterviewQuestion({
    companyId,
    addedBy,
    name,
    question,
    language,
    code,
    interviewerNotes,
    tags,
    env,
    limit,
    pageNo,
    filters,
    sortBy,
}) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/integrations/dashboard/create_interview_question`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    addedBy,
                    name,
                    question,
                    language,
                    code,
                    interviewerNotes,
                    tags,
                }),
            }
        )
    );

    const rand2 = yield call(() =>
        getInterviewQuestions({
            env,
            companyId,
            limit,
            pageNo,
            filters,
            sortBy,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    NProgress && NProgress.done();
}

function* createPair({
    companyId,
    candidateName,
    candidateEmailId,
    candidatePhoneNumber,
    requesterEmailId,
    requesterName,
    instructions,
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
}) {
    NProgress && NProgress.start();

    // console.log("starting shit", Date.now());
    const data = yield call(() =>
        request(
            getAPIDomain() + `/app/api/integrations/dashboard/upload_pair`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    candidateName,
                    candidateEmailId,
                    candidatePhoneNumber,
                    requesterEmailId,
                    requesterName,
                    instructions,
                }),
            }
        )
    );

    if (!data.success) {
        sendErrorToast(
            data.error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
    } else {
        sendToast("Sent pair programming link over email to candidate.");
    }

    const rand2 = yield call(() =>
        getPairsData({
            companyId,
            limit,
            pageNo,
            filters,
            sortBy,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    NProgress && NProgress.done();
}

function* getPairsData({
    limit,
    pageNo,
    filters = [],
    sortBy = "none",
    companyId,
}) {
    NProgress && NProgress.start();

    // console.log("starting shit", Date.now());
    const data = yield call(() =>
        request(getAPIDomain() + `/app/api/integrations/dashboard/list_pairs`, {
            method: "POST",
            body: JSON.stringify({
                companyId,
                limit,
                pageNo,
                sortBy,
                filters,
            }),
        })
    );

    yield put(
        getPairsDataSuccess({
            companyId,
            interviews: data.interviews,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    NProgress && NProgress.done();
}

function* getInterviewsData({
    limit,
    pageNo,
    companyId,
    filters,
    sortBy,
    env,
}) {
    NProgress && NProgress.start();

    if (filters && filters.startDate && typeof filters.startDate == "object") {
        filters.startDate =
            new Date(filters.startDate.format("MM/DD/YYYY")).getTime() + 0;
    }
    if (filters && filters.endDate) {
        if (typeof filters.endDate == "object") {
            filters.endDate =
                new Date(filters.endDate.format("MM/DD/YYYY")).getTime() +
                24 * 60 * 60 * 1000;
        } else {
            filters.endDate = filters.endDate + 24 * 60 * 60 * 1000;
        }
    }

    // console.log("starting shit", Date.now());
    const data = yield call(() =>
        request(
            getAPIDomain() + `/app/api/integrations/dashboard/list_interviews`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    limit,
                    pageNo,
                    sortBy,
                    filters,
                }),
            }
        )
    );

    yield put(
        interviewsDataSuccess({
            companyId,
            interviews: data.interviews,
        })
    );

    NProgress && NProgress.done();
}

function* getInterviewDetails({ interviewId, companyId }) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(
            getAPIDomain() + `/app/api/integrations/dashboard/interview_detail`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                }),
            }
        )
    );

    yield put(getInterviewDetailsSuccess({ interviewId, companyId, data }));

    NProgress && NProgress.done();
}

function* getShallowInterviewsData({
    limit,
    pageNo,
    companyId,
    filters,
    sortBy,
    env,
    view,
}) {
    NProgress && NProgress.start();

    if (filters && filters.startDate && typeof filters.startDate == "object") {
        filters.startDate =
            new Date(filters.startDate.format("MM/DD/YYYY")).getTime() + 0;
    }
    if (filters && filters.endDate) {
        if (typeof filters.endDate == "object") {
            filters.endDate =
                new Date(filters.endDate.format("MM/DD/YYYY")).getTime() +
                24 * 60 * 60 * 1000;
        } else {
            filters.endDate = filters.endDate + 24 * 60 * 60 * 1000;
        }
    }

    // console.log("starting shit", Date.now());
    const data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/integrations/dashboard/shallow_interviews`,
            {
                method: "POST",
                body: JSON.stringify({
                    view,
                    companyId,
                    limit,
                    pageNo,
                    sortBy,
                    filters,
                }),
            }
        )
    );

    yield put(
        getShallowInterviewsDataSuccess({
            companyId,
            interviews: data.interviews,
            accountDeactivated: data.accountDeactivated,
            filters,
            pageNo,
            view,
        })
    );

    NProgress && NProgress.done();
}

function* getHighLevelInterviewsTotal({
    limit,
    companyId,
    filters,
    sortBy,
    env,
}) {
    NProgress && NProgress.start();

    if (filters && filters.startDate && typeof filters.startDate == "object") {
        filters.startDate =
            new Date(filters.startDate.format("MM/DD/YYYY")).getTime() + 0;
    }
    if (filters && filters.endDate) {
        if (typeof filters.endDate == "object") {
            filters.endDate =
                new Date(filters.endDate.format("MM/DD/YYYY")).getTime() +
                24 * 60 * 60 * 1000;
        } else {
            filters.endDate = filters.endDate + 24 * 60 * 60 * 1000;
        }
    }

    // console.log("starting shit", Date.now());
    const data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/integrations/dashboard/high_level_interview_counts`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    limit,
                    sortBy,
                    filters,
                }),
            }
        )
    );
    if ((data || {}).success) {
        yield put(
            getHighLevelInterviewsTotalSuccess({
                companyId,
                total: data.counts,
            })
        );
    }

    NProgress && NProgress.done();
}

function* addCompany({ id, name, env }) {
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/company/new", {
            method: "POST",
            body: JSON.stringify({
                companyName: name,
            }),
        })
    );
}

function* getQuestionsData({
    env,
    companyId,
    isAdmin,
    limit,
    pageNo,
    filters,
}) {
    if (!isAdmin) {
        sendErrorToast("Insufficient permissions to fetch questions");
        yield put(
            getQuestionsSuccess({
                env,
                companyId,
                data: {
                    status: "failed",
                },
            })
        );
        return;
    }
    const { success, questions, next, total } = yield call(() =>
        request(getAPIDomain() + "/app/questions/questions-library", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                env,
                isAdmin: !!isAdmin,
                pageNo: pageNo || 1,
                limit: limit || 50,
                filters,
            }),
        })
    );

    if (!success) {
        sendErrorToast("Error fetching questions");
        yield put(
            getQuestionsSuccess({
                env,
                companyId,
                data: {
                    status: "failed",
                },
            })
        );
    } else {
        yield put(
            getQuestionsSuccess({
                env,
                companyId,
                data: {
                    questions: Object.values(questions || {}),
                    next,
                    total,
                    status: "success",
                    filters,
                },
                pageNo,
                ignoreAllCategories: true,
            })
        );
    }
}

function* getQuestionLibrary({
    env,
    companyId,
    limit,
    pageNo,
    filters,
    isAdmin,
}) {
    const { success, questions, next, total, allCategories } = yield call(() =>
        request(getAPIDomain() + "/app/api/question-library", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                env,
                pageNo: pageNo || 1,
                limit: limit || 50,
                filters,
                ...(isAdmin ? { isAdmin } : {}),
            }),
        })
    );

    if (!success) {
        sendErrorToast("Error fetching questions");
        yield put(
            getQuestionsSuccess({
                env,
                companyId,
                data: {
                    status: "failed",
                },
            })
        );
    } else {
        let questionValues = Object.values(questions || {});

        questionValues.sort(
            (a, b) =>
                (b.metadata?.createdAt || 0) - (a.metadata?.createdAt || 0)
        );

        yield put(
            getQuestionsSuccess({
                env,
                companyId,
                data: {
                    questions: questionValues,
                    next,
                    total,
                    status: "success",
                    filters,
                    allCategories,
                },
                pageNo,
            })
        );
    }
}

function* getAllCategories() {
    const { success, allCategoryMap } = yield call(() =>
        request(getAPIDomain() + "/app/questions/all-categories", {
            method: "GET",
        })
    );

    if (!success) {
        sendErrorToast("Error fetching categories");
        yield put(
            getAllCategoriesSuccess({
                data: {
                    status: "failed",
                },
            })
        );
        return;
    }

    yield put(
        getAllCategoriesSuccess({
            categories: allCategoryMap,
        })
    );
}

function* getQuestionsDataForTest({ env, companyId, testId, isAdmin }) {
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/company/questions-test", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                env,
                testId,
                isAdmin: !!isAdmin,
            }),
        })
    );

    yield put(
        getQuestionsTestSuccess({
            env,
            companyId,
            testId,
            questions: data.questions || {},
        })
    );
}

function* getScorecardData({ interviewId, companyId, env }) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/interview_scorecard",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    env,
                }),
            }
        )
    );

    const { interviewData, status, ipProctoringDetails } = data;
    yield put(
        getScorecardDataSuccess({
            interviewId,
            companyId,
            interviewData: {
                interviewData,
                status,
                ipProctoringDetails,
            },
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    NProgress && NProgress.done();
}

function* getScorecardInterviewData({ interviewId, companyId, env }) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/interview_data",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    env,
                }),
            }
        )
    );

    const { success, interviewData, status, error } = data;
    if (success) {
        yield put(
            getScorecardInterviewDataSuccess({
                interviewId,
                companyId,
                interviewData: {
                    interviewData,
                    status,
                },
            })
        );
    } else {
        if (error !== "Unauthorized") {
            sendErrorToast(error || "Problem fetching details");
        }
    }

    NProgress && NProgress.done();
}

function* getScorecardIpDetails({ interviewId, companyId, env }) {
    NProgress && NProgress.start();

    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/ip_proctoring_details",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    env,
                }),
            }
        )
    );
    if ((data || {}).success) {
        yield put(
            getScorecardIpDetailsSuccess({
                interviewId,
                companyId,
                ipProctoringDetails: (data || {}).data,
            })
        );
    }

    NProgress && NProgress.done();
}

function* updateCard({
    companyId,
    token,
    env,
    emailId,
    name,
    address_line1,
    address_city,
    address_country,
    address_zip,
}) {
    NProgress && NProgress.start();
    yield put(
        updateCardStatus({
            status: "loading",
            message: "Card is being added at the moment",
        })
    );

    const data = yield call(() =>
        request(getAPIDomain() + "/app/stripe/card/update", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                tokenId: token.id,
                emailId,
                name,
                address_line1,
                address_city,
                address_country,
                address_zip,
            }),
        })
    );

    // TODO: this is not optimized. but will work for now
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    const { success, error } = data;
    if (success) {
        const rand1 = yield call(() =>
            fetchAddress({
                companyId,
            })
        );
        yield put(
            updateCardStatus({
                status: "success",
                message: "Card added successfully",
            })
        );
        sendToast("Card updated Successfully");
    } else {
        // TODO: convey actual error.
        yield put(
            updateCardStatus({
                status: "failure",
                message: error || "Unable to add card. Please try again.",
            })
        );
        sendErrorToast(error || "Unable to add card. Please try again.");
    }
    // console.log(data);
    // TODO: (update UI accordingly)
    NProgress && NProgress.done();
}

function* switchPlans({
    env,
    companyId,
    planId,
    planName,
    planCredits,
    appliedCoupon,
    token,
    emailId,
    name,
    address_line1,
    address_city,
    address_state,
    address_zip,
    address_country,
    recruiterName,
}) {
    NProgress && NProgress.start();
    let data;

    if (token) {
        yield put(
            updateCardStatus({
                status: "loading",
                message: "Card is being added at the moment",
            })
        );

        data = yield call(() =>
            request(getAPIDomain() + "/app/stripe/card/update", {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    tokenId: token ? token.id : "",
                    emailId,
                    name,
                    address_line1,
                    address_city,
                    address_state,
                    address_zip,
                    address_country,
                }),
            })
        );
    } else {
        yield put(
            updateCardStatus({
                status: "loading",
                message: "Switching your plan",
            })
        );
    }

    let data2;
    if ((token && data.success) || !token) {
        data2 = yield call(() =>
            request(getAPIDomain() + "/app/api/company/subscribe", {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    planId,
                    appliedCoupon,
                    isPairPlan: planId.indexOf("pair") === -1 ? false : true,
                    email: emailId,
                    name: recruiterName,
                }),
            })
        );
    }

    const { success, error } = data || {};

    const { success: success2, error: error2, subscription } = data2 || {};

    if ((success && success2) || (!token && success2)) {
        // TODO: this is not optimized. but will work for now
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );
        if (planName && planName !== "free") {
            const notify = yield call(() =>
                triggerKnockNotification({
                    companyId,
                    action: "plan-purchase",
                    message: `${emailId} purchased the Adaface ${planName} plan (${planCredits} credits)`,
                    data: { planId, appliedCoupon },
                })
            );

            try {
                if (typeof gtag !== "undefined" && gtag) {
                    gtag("event", "ab_split_test", {
                        test_event: "plan_purchase",
                        test_event_value: planName,
                    });
                }
            } catch (e) {}
        }
        yield put(
            updateCardStatus({
                status: "success",
                message: "Plans switched successfully",
                subscription,
            })
        );

        if (
            subscription &&
            subscription.latest_invoice &&
            subscription.latest_invoice.payment_intent &&
            subscription.latest_invoice.payment_intent.status &&
            subscription.latest_invoice.payment_intent.status ===
                "requires_action"
        ) {
            // steps are left
        } else {
            sendToast("Plans switched successfully");
            yield call(() =>
                getSubscriptionInvoices({
                    companyId,
                })
            );
        }
    } else {
        yield put(
            updateCardStatus({
                status: "failure",
                message:
                    error ||
                    error2 ||
                    "Unable to switch your plan. Please try again.",
            })
        );
        sendErrorToast(
            (error || "").startsWith("No such customer:") ||
                (error2 || "").startsWith("No such customer:")
                ? "Remove and add your card details to continue."
                : error ||
                      error2 ||
                      "Unable to switch your plan. Please try again."
        );
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );
    }
    NProgress && NProgress.done();
}

function* updateCreditsManually({ operation, companyId }) {
    NProgress && NProgress.start();

    const { success, message, credit } = yield call(() =>
        request(getAPIDomain() + "/app/api/admin/update-credits-manually", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                operation,
            }),
        })
    );

    if (!success) {
        sendErrorToast(message || "Error updating credits");
    } else {
        sendToast(message || "Credits updated successfully");
    }
    yield put(
        updateCreditsManuallyStatus({ status: success, companyId, credit })
    );
    NProgress && NProgress.done();
}

function* sendDiscordMessage(action) {
    let discordChannel = "DISCORD_LOGS_GENERIC_ALERTS_WEBHOOK";
    const { message, channel, data } = action;

    if (channel === "logs-test-requests") {
        discordChannel = "DISCORD_LOGS_TEST_REQUESTS_WEBHOOK";
    }

    if (channel === "logs-companies-core") {
        discordChannel = "DISCORD_LOGS_COMPANIES_CORE_WEBHOOK";
    }

    if (channel === "logs-talk-requests") {
        discordChannel = "DISCORD_LOGS_TALK_REQUESTS_WEBHOOK";
    }

    if (channel === "logs-companies-plans") {
        discordChannel = "DISCORD_LOGS_COMPANIES_PLANS_WEBHOOK";
    }

    if (channel === "logs-preview-test") {
        discordChannel = "DISCORD_LOGS_PREVIEW_TEST_WEBHOOK";
    }

    if (channel === "logs-adaface-alerts") {
        discordChannel = "DISCORD_ALERTS_WEBHOOK";
    }

    sendDiscordAlert({
        isDev:
            process.env.NODE_ENV !== "production" ||
            process.env.SUBDOMAIN === "tester",
        message,
        channel: discordChannel,
        data,
    });
}

function* triggerKnockNotification({ companyId, action, message, data = {} }) {
    const baseUrl = "https://api.knock.app/v1";
    const userUrl = `${baseUrl}/users/${companyId}`;
    const user = yield call(() =>
        fetch(userUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_KNOCK_SECRET_API_KEY}`,
            },
        })
    );

    const triggerUrl = `${baseUrl}/workflows/in-app-notification/trigger`;
    fetch(triggerUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_KNOCK_SECRET_API_KEY}`,
        },
        body: JSON.stringify({
            recipients: [companyId],
            cancellationKey: "cancel-in-app-notification",
            data: { action, message, ...data },
        }),
    });
}

function* sendRejectionEmail({
    env,
    interviewId,
    companyId,
    limit,
    pageNo,
    filters,
    sortBy,
    emailId,
    candidateEmailId,
    percentage,
    replyTo,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/send_rejection_email",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    replyTo,
                }),
            }
        )
    );
    if ((data || {}).success) {
        sendToast("Sent rejection email to candidate.");
        if (emailId && candidateEmailId) {
            const notify = yield call(() =>
                triggerKnockNotification({
                    companyId,
                    action: "sent-rejection-email",
                    message: `${emailId} sent rejection email to ${candidateEmailId} (${percentage}%)`,
                    data: {
                        interviewId,
                    },
                })
            );
            const alert = yield call(() =>
                sendDiscordMessage({
                    channel: "logs-companies-core",
                    message: JSON.stringify(
                        {
                            "interview-id": interviewId,
                            "company-id": companyId,
                            "sender-email": emailId,
                            "candidate-email": candidateEmailId,
                            "email-type": "reject",
                        },
                        null,
                        4
                    ),
                })
            );
        }
    } else {
        sendErrorToast(data.error || "Some problem ocurred. Please try again.");
    }

    // TODO: update just this interview data here. for now refreshing all candidate data.

    const rand2 = yield call(() =>
        getInterviewDetails({
            interviewId,
            companyId,
        })
    );
}

function* registerCandidatePairDetails({
    interviewId,
    limit,
    pageNo,
    filters = [],
    sortBy,
    candidateName,
    candidatePhoneNumber,
    candidateEmailId,
    instructions,
    companyId,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/register_pair_data",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    candidateEmailId,
                    candidateName,
                    candidatePhoneNumber,
                    instructions,
                    companyId,
                }),
            }
        )
    );

    // TODO: update just this interview data here. for now refreshing all candidate data.
    const rand2 = yield call(() =>
        getPairsData({
            companyId,
            limit,
            pageNo,
            filters,
            sortBy,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
}

function* sendPairInviteEmail({
    env,
    companyId,
    interviewId,
    limit,
    pageNo,
    filters,
    sortBy,
    candidateName,
    candidateEmailId,
    candidatePhoneNumber,
    instructions,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/send_pair_email",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    candidateEmailId,
                    candidateName,
                    candidatePhoneNumber,
                    instructions,
                }),
            }
        )
    );
    if ((data || {}).success) {
        sendToast("Sent pair programming link over email to candidate.");
    } else {
        sendErrorToast(
            data.error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
    }

    // TODO: update just this interview data here. for now refreshing all candidate data.

    // Avoiding this here since we call this along with register data most of the times. and that call has this.
    // const rand2 = yield call(() =>
    //  getPairsData({
    //    env,
    //    companyId,
    //    limit,
    //    pageNo,
    //    filters,
    //    sortBy,
    //  })
    // );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
}

function* resendCandidateInviteEmail({ interviewId }) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/resend_candidate_invite_email",
            {
                method: "POST",
                body: JSON.stringify({
                    interviewId,
                }),
            }
        )
    );
    if ((data || {}).success) {
        sendToast("Resent invite email to candidate.");
        // TODO: update just this interview data here. for now refreshing all candidate data.
    } else {
        sendErrorToast(
            (data || {}).error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
    }
}

function* sendShortlistEmail({
    env,
    interviewId,
    companyId,
    limit,
    pageNo,
    filters,
    sortBy,
    emailId,
    candidateEmailId,
    percentage,
    replyTo,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/send_shortlist_email",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                    replyTo,
                }),
            }
        )
    );
    if ((data || {}).success) {
        sendToast("Sent shortlist email to candidate.");
        if (emailId && candidateEmailId) {
            const notify = yield call(() =>
                triggerKnockNotification({
                    companyId,
                    action: "sent-shortlisted-email",
                    message: `${emailId} sent shortlisted email to ${candidateEmailId} (${percentage}%)`,
                    data: {
                        interviewId,
                    },
                })
            );
            const alert = yield call(() =>
                sendDiscordMessage({
                    channel: "logs-companies-core",
                    message: JSON.stringify(
                        {
                            "interview-id": interviewId,
                            "company-id": companyId,
                            "sender-email": emailId,
                            "candidate-email": candidateEmailId,
                            "email-type": "shortlist",
                        },
                        null,
                        4
                    ),
                })
            );
        }
        // TODO: update just this interview data here. for now refreshing all candidate data.
    } else {
        sendErrorToast(
            (data || {}).error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
    }

    const rand2 = yield call(() =>
        getInterviewDetails({
            interviewId,
            companyId,
        })
    );
}

function* sendBulkResultEmailToCandidate({
    action,
    interviewIds,
    companyId,
    notificationDetails,
    replyTo,
    env,
    pageNo,
    filters,
    sortBy,
    limit,
}) {
    const { success, error } = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/send_bulk_result_email_to_candidate",
            {
                method: "POST",
                body: JSON.stringify({
                    action,
                    companyId,
                    interviewIds,
                    emailId: notificationDetails.emailId,
                    replyTo,
                }),
            }
        )
    );

    if (error || !success) {
        yield put(sendBulkResultEmailToCandidateStatus({ status: "failed" }));
        sendErrorToast(
            error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
        return;
    } else {
        yield put(sendBulkResultEmailToCandidateStatus({ status: "success" }));

        const rand3 = yield call(() =>
            getHighLevelInterviewsTotal({
                limit,
                env,
                companyId,
                filters,
                sortBy,
            })
        );

        yield call(() =>
            getShallowInterviewsData({
                view: "table",
                env,
                companyId,
                limit,
                pageNo,
                filters,
                sortBy,
            })
        );

        sendToast("Sent bulk " + action + " email to candidates.");

        yield call(() =>
            triggerKnockNotification({
                companyId,
                action:
                    action === "shortlist"
                        ? "sent-bulk-shortlisted-email"
                        : action === "rejection"
                          ? "sent-bulk-rejection-email"
                          : "",
                message: `${notificationDetails.emailId} sent bulk ${action} email to ${interviewIds.length} candidates.`,
                data: {
                    interviewIds,
                },
            })
        );
    }
}

function* sendReminderEmail({
    env,
    interviewId,
    companyId,
    limit = 25,
    pageNo = 1,
    filters = "none",
    sortBy = "",
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/send_reminder_email",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    interviewId,
                }),
            }
        )
    );
    if ((data || {}).success) {
        sendToast("Sent reminder email to candidate.");
    } else {
        sendErrorToast(
            (data || {}).error ||
                "Error sending email. Send email manually or reach out to us for support"
        );
    }

    // TODO: update just this interview data here. for now refreshing all candidate data.

    const rand2 = yield call(() =>
        getInterviewsData({
            env,
            companyId,
            limit,
            pageNo,
            filters,
            sortBy,
        })
    );
}

function* updateTestEmailTemplates({
    companyId,
    env,
    testId,
    templateName,
    templateKey,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/intgegrations/dashboard/update_test_email_templates",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    env,
                    templateKey,
                    templateName,
                }),
            }
        )
    );

    // TODO: update just this test not entier company
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
    sendToast("Updated email templates successfully");
}

function* updateTestName({ env, testId, companyId, name }) {
    NProgress && NProgress.inc();
    if (name && name.length > 100) {
        sendErrorToast("Test Name cannot be more than 100 characters");
        yield put(
            changeNewTestNameStatus({
                status: "failure",
            })
        );
    } else {
        const data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/update_test_name",
                {
                    method: "POST",
                    body: JSON.stringify({
                        companyId,
                        testId,
                        env,
                        name,
                    }),
                }
            )
        );

        if ((data || {}).success) {
            const rand = yield call(() =>
                fetchUserDetails({
                    env,
                    userId: companyId,
                })
            );

            sendToast(`Test name changed to "${name}"`);
        } else {
            sendErrorToast(
                ((data || {}).error || "") ===
                    "Data too long for column 'displayName' at row 1"
                    ? "Test Name cannot exceeds more than 100 characters"
                    : "Failed to update assessment name"
            );
        }

        yield put(
            changeNewTestNameStatus({
                status: data.success ? "success" : "failure",
            })
        );
        // TODO: update just this test not entier company
    }
    NProgress && NProgress.done(true);
}

function* updateTestTimeboxed({ env, testId, companyId, timeboxed }) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/intgegrations/dashboard/update_test_timeboxed",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    env,
                    timeboxed,
                }),
            }
        )
    );

    // TODO: update just this test not entier company
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
    sendToast(
        timeboxed
            ? "Assessment will be timeboxed from now"
            : "Assessment will not be timeboxed from now"
    );
}

function* updateTestProctoringMode({
    env,
    testId,
    companyId,
    proctoringMode,
    videoProctoringMode,
    requireFullscreen,
    randomizeQuestions,
    requireScreenSharing,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/intgegrations/dashboard/update_test_proctoring_mode",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    env,
                    proctoringMode,
                    videoProctoringMode,
                    requireFullscreen,
                    randomizeQuestions,
                    requireScreenSharing,
                }),
            }
        )
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    // TODO: update just this test not entier company
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    sendToast("Change made successfully");
}

function* updateTestThreshold({ env, testId, companyId, threshold }) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/intgegrations/dashboard/update_test_threshold",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    env,
                    threshold,
                }),
            }
        )
    );

    // TODO: update just this test not entier company
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
    sendToast("Updated threshold of the assessment successfully.");
}

function* toggleTestOnelink({ env, testId, companyId, onelink, turnoff }) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/test_toggle_onelink",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    onelink,
                    turnoff,
                    env,
                }),
            }
        )
    );

    // TODO: update just this test not entier company
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
    sendToast(`Link is turned ${turnoff ? "off" : "on"} successfully`);
    NProgress && NProgress.done(true);
}

function* generateTestOnelink({ env, testId, companyId }) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/test_generate_onelink",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    env,
                }),
            }
        )
    );
    if ((data || {}).success) {
        // TODO: update just this test not entier company
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        sendToast("Generated new link");
        yield put(
            generateTestOnelinkSuccess({
                status: "success",
            })
        );
    } else {
        sendErrorToast("Error generating new link");
        yield put(
            generateTestOnelinkSuccess({
                status: "failed",
            })
        );
    }

    NProgress && NProgress.done(true);
}

function* updateTestMetadata({ env, testId, metadata, companyId, reason }) {
    yield put(
        updateTestMetadataLoader({
            show: true,
        })
    );

    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/intgegrations/dashboard/update_test_metadata",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    metadata,
                    env,
                }),
            }
        )
    );

    // TODO: update just this test not entier company
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );

    yield put(
        updateTestMetadataLoader({
            show: false,
        })
    );

    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );

    if (reason === "update_role_name") {
        sendToast("Updated role name");
    } else if (reason === "update_disable_hints") {
        sendToast(
            metadata.disableHints ? "Hints are disabled" : "Hints are enabled"
        );
    } else if (reason === "update_markdown") {
        sendToast("Updated public link instructions");
    } else {
        sendToast("Updated test metadata");
    }
}

function* updateTestSubscribers({
    env,
    testId,
    companyId,
    invitingName,
    invitingEmailId,
    subscribers,
    newUsers,
    sub = false,
}) {
    // console.log("am here", subscribers, companyId, testId);
    yield put(
        updateTestSubscribersLoader({
            show: true,
        })
    );

    if (newUsers && newUsers.length > 0) {
        // newUsers.map(x => {
        const data = yield call(() =>
            editUsers({
                env,
                companyId,
                userEmailId: newUsers[0],
                name: "",
                invitingName,
                invitingEmailId,
            })
        );
        // })
    }

    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/intgegrations/dashboard/update_test_subscribers",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    subscribers,
                    env,
                }),
            }
        )
    );

    if ((data || {}).success) {
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );
        if (!sub) {
            sendToast("Updated notification settings");
        }
    } else {
        if (!sub) {
            sendErrorToast(
                (data || {}).error === "Unauthorized"
                    ? "You do not have permissions to change this. Please contact your admin."
                    : (data || {}).error
            );
        }
    }
    // TODO: update just this test not entier company

    yield put(
        updateTestSubscribersLoader({
            show: false,
        })
    );
}

function* deleteTest({ env, testId, companyId, savedTests }) {
    NProgress && NProgress.inc();
    yield put(
        deleteTestStatus({
            status: "in-progress",
        })
    );

    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/delete_test",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testId,
                    env,
                    savedTests,
                }),
            }
        )
    );

    if ((data || {}).success) {
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        sendToast(`Deleted test successfully`);
        yield put(
            deleteTestStatus({
                status: "success",
            })
        );
    } else {
        sendErrorToast(
            (data || {}).error || "Oops! Couldn't delete test. Try again."
        );
        yield put(
            deleteTestStatus({
                status: "failed",
            })
        );
    }

    // TODO: update just this test not entier company

    NProgress && NProgress.done(true);
}

function* compileCode(action) {
    const { index, mode, code, modeVersion, filename, stdin, interviewId } =
        action;

    const data = {
        code,
        lang: mode,
        stdin,
    };

    // if (modeVersion) {
    // data["modeVersion"] = modeVersion;
    // }

    const url = getAPIDomain() + "/app/api/code/execute";

    // TODO: add fail safe to sphere

    // console.log(url);
    const result = yield call(() =>
        request(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
    );

    const { stdout, stderr, error } = result;
    const glotstdout = stdout;
    const glotstderr = stderr;
    const gloterror = error;

    yield put(
        compileCodeSuccess({
            interviewId,
            index,
            glotstdout,
            glotstderr,
            gloterror,
        })
    );
}

function* updateUserActivity({ emailId, companyId, env }) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/update_user_activity",
            {
                method: "POST",
                body: JSON.stringify({
                    env,
                    emailId,
                    companyId,
                }),
            }
        )
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
}

function* addNewTestRequest({
    env,
    companyId,
    testUrl,
    requesterName,
    requesterEmailId,
    testName,
    jd,
    comments,
    experience,
    algorithms,
    coding,
    skills,
    salary,
    testLanguage,
}) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/request_assessment",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    testUrl,
                    requesterName,
                    requesterEmailId,
                    testName,
                    jd,
                    comments,
                    algorithms,
                    coding,
                    skills,
                    salary,
                    experience,
                    ...(testLanguage ? { testLanguage } : {}),
                }),
            }
        )
    );

    if ((data || {}).success) {
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        yield put(addNewTestRequestSuccess({ status: "success", testUrl }));
    } else if (data?.error) {
        sendErrorToast(data.error);
        yield put(addNewTestRequestSuccess({ status: "failure" }));
    } else {
        sendErrorToast(
            "Some problem occured while requesting. Please try again."
        );
        yield put(addNewTestRequestSuccess({ status: "failure" }));
    }

    NProgress && NProgress.done(true);
}

function* removeSampleCandidates({
    view,
    env,
    companyId,
    limit,
    pageNo,
    filters,
    sortBy,
}) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/remove_sample_candidates",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                }),
            }
        )
    );

    yield call(() =>
        getHighLevelInterviewsTotal({
            limit,
            env,
            companyId,
            filters,
            sortBy,
        })
    );

    yield call(() =>
        getShallowInterviewsData({
            env,
            view,
            companyId,
            limit,
            pageNo,
            filters,
            sortBy,
        })
    );

    sendToast("Sample candidates are removed");

    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
    NProgress && NProgress.done(true);
}

function* deleteInterview({
    env,
    companyId,
    interviewId,
    deleteAll,
    view,
    fromColumn,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/delete_interview",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                    interviewId,
                    deleteAll,
                }),
            }
        )
    );

    if ((data || {}).success) {
        sendToast(
            deleteAll
                ? "Candidate entry is deleted"
                : "Candidate entry is anonymized"
        );

        yield put(
            deleteInterviewSuccess({
                status: "success",
                interviewId,
                view,
                fromColumn,
            })
        );
    } else if ((data || {}).error) {
        sendErrorToast((data || {}).error);
    }

    // TODO: update just this interview data here. for now refreshing all candidate data.

    // const rand2 = yield call(() =>
    //  getInterviewsData({
    //    env,
    //    companyId,
    //    limit,
    //    pageNo,
    //    filters,
    //    sortBy,
    //  })
    // );
}

function* endInterview({ env, companyId, interviewId, end }) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/end_interview",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                    interviewId,
                    end,
                }),
            }
        )
    );

    sendToast(
        end ? "Candidate test is ended now" : "Candidate test is open now"
    );
    // TODO: update just this interview data here. for now refreshing all candidate data.

    const rand2 = yield call(() =>
        getInterviewDetails({
            interviewId,
            companyId,
        })
    );

    yield put(endInterviewSuccess({ status: "success", interviewId }));

    NProgress && NProgress.done(true);
}

function* removeQuestionFromScorecard({
    env,
    companyId,
    interviewId,
    questionId,
}) {
    const data = yield call(() =>
        request(flowDomain + "/interview/admin-skip-question-from-scorecard", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                env,
                interviewId,
                questionId,
            }),
        })
    );

    if ((data || {}).success) {
        sendToast(
            "Question removed from scorecard. Wait for a minute for changes to reflect."
        );
    } else {
        sendErrorToast(
            (data || {}).error || "Error removing question from scorecard"
        );
    }
}

function* reviewInterview({
    view,
    env,
    companyId,
    interviewId,
    shortlisted,
    archived,
    pageNo,
    filters,
    sortBy,
    limit,
    action,
    emailId,
    candidateEmailId,
    percentage,
    fromColumn,
    toColumn,
}) {
    if (view === "kanban" && fromColumn !== "expired") {
        yield put(
            reviewInterviewSuccess({
                status: "in-progress",
                interviewId,
                view,
                action,
                shortlisted,
                archived,
                fromColumn,
                toColumn: toColumn
                    ? toColumn
                    : action === "shortlist"
                      ? "shortlisted"
                      : "archived",
            })
        );
    }
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/review_interview",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                    interviewId,
                    shortlisted,
                    archived,
                    action,
                }),
            }
        )
    );
    if ((data || {}).success) {
        if (view === "table" || fromColumn === "expired") {
            sendToast(
                action === "shortlist"
                    ? "Marked candidate as shortlisted"
                    : action === "archive"
                      ? "Marked candidate as archived"
                      : action === "unmark-shortlist"
                        ? "Candidate unmarked as shortlisted"
                        : action === "unmark-archive"
                          ? "Candidate unmarked as archived"
                          : action === "reset"
                            ? "Candidate unmarked successfully"
                            : ""
            );
        } else {
            sendToast(
                action === "shortlist"
                    ? `Moved candidate to "Shortlisted"`
                    : action === "archive"
                      ? `Moved candidate to "Archived"`
                      : action === "reset"
                        ? `Moved candidate to "To review"`
                        : ""
            );
        }
        if (
            (action === "shortlist" || action === "archive") &&
            emailId &&
            candidateEmailId
        ) {
            const notify = yield call(() =>
                triggerKnockNotification({
                    companyId,
                    action:
                        action === "shortlist"
                            ? "marked-shortlisted"
                            : action === "archive"
                              ? "marked-archived"
                              : "",
                    message: `${emailId} marked ${candidateEmailId} (${percentage}%) as ${
                        action === "shortlist" ? "shortlisted" : "archived"
                    }`,
                    data: {
                        interviewId,
                    },
                })
            );
        } // TODO: update just this interview data here. for now refreshing all candidate data.

        const rand2 = yield call(() =>
            getInterviewDetails({
                interviewId,
                companyId,
            })
        );

        const rand3 = yield call(() =>
            getHighLevelInterviewsTotal({
                limit,
                env,
                companyId,
                filters,
                sortBy,
            })
        );
        if (view === "table") {
            const rand4 = yield call(() =>
                getShallowInterviewsData({
                    view,
                    env,
                    companyId,
                    limit,
                    pageNo,
                    filters,
                    sortBy,
                    view,
                })
            );
        }
    } else {
        sendErrorToast("Error in updating candidate status. Please try again.");
        if (view === "kanban" && fromColumn !== "expired") {
            yield put(
                reviewInterviewSuccess({
                    status: "failed",
                    interviewId,
                    view,
                    action,
                    shortlisted,
                    archived,
                    fromColumn: toColumn
                        ? toColumn
                        : action === "shortlist"
                          ? "shortlisted"
                          : "archived",
                    toColumn: fromColumn,
                })
            );
        }
    }
}

function* reviewBulkInterviews({
    env,
    companyId,
    reviewInterviews,
    pageNo,
    filters,
    sortBy,
    limit,
    action,
    emailId,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/review_bulk_interviews",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                    reviewInterviews,
                    pageNo,
                    filters,
                    sortBy,
                    limit,
                    action,
                    emailId,
                }),
            }
        )
    );
    if ((data || {}).success) {
        // TODO: update just this interview data here. for now refreshing all candidate data.

        const notify = yield call(() =>
            triggerKnockNotification({
                companyId,
                action: "marked-" + action,
                message: `${emailId} marked ${(
                    Object.values(reviewInterviews || {}) || []
                )
                    .map(
                        (interview) =>
                            `${interview.candidateEmailId} (${interview.percentage}%)`
                    )
                    .join(", ")} as ${
                    action === "bulk-shortlisted" ? "shortlisted" : "archived"
                }`,
                data: {
                    reviewInterviews,
                },
            })
        );
        const rand3 = yield call(() =>
            getHighLevelInterviewsTotal({
                limit,
                env,
                companyId,
                filters,
                sortBy,
            })
        );
        const rand4 = yield call(() =>
            getShallowInterviewsData({
                env,
                companyId,
                limit,
                pageNo,
                filters,
                sortBy,
                view: "table",
            })
        );
        yield put(reviewBulkInterviewsStatus({ status: "success", action }));
        sendToast(
            action === "bulk-shortlisted"
                ? "Marked as shortlisted"
                : action === "bulk-archived"
                  ? "Marked as archived"
                  : "Candidate detail changed"
        );
    } else {
        yield put(reviewBulkInterviewsStatus({ status: "failed", action }));
        sendErrorToast("Error while upading candidates details");
    }
}

function* cancelInterview({
    env,
    view,
    companyId,
    interviewId,
    admin,
    emailId,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/cancel_interview",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                    interviewId,
                    admin,
                    emailId,
                }),
            }
        )
    );

    if ((data || {}).success) {
        sendToast("Interview link cancelled");
        // TODO: update just this interview data here. for now refreshing all candidate data.

        const rand2 = yield call(() =>
            getInterviewDetails({
                interviewId,
                companyId,
            })
        );
        yield put(
            cancelInterviewSuccess({ status: "success", interviewId, view })
        );
    }
}

function* cancelBulkInterviews({
    env,
    companyId,
    interviewIds,
    admin,
    emailId,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/cancel_bulk_interview",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    env,
                    interviewIds,
                    admin,
                    emailId,
                }),
            }
        )
    );

    if ((data || {}).success) {
        sendToast(
            `Test invite${(interviewIds || []).length > 1 ? "s" : ""} cancelled`
        );
        // TODO: update just this interview data here. for now refreshing all candidate data.

        yield put(
            cancelBulkInterviewsStatus({ status: "success", interviewIds })
        );
    } else {
        sendErrorToast("Failed to cancel test.");

        yield put(
            cancelBulkInterviewsStatus({ status: "failed", interviewIds })
        );
    }
}

function* addUpdateEmailTemplate({
    templateName,
    htmlString,
    env,
    subjectString,
    subjectWithCandidateName,
    subjectWithoutCandidateName,
    htmlTemplate,
    companyId,
}) {
    sendToast(`Updating email templates`);
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/add-email-template",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    templateName,
                    htmlString,
                    subjectString,
                    subjectWithCandidateName,
                    subjectWithoutCandidateName,
                    htmlTemplate: JSON.stringify(htmlTemplate),
                }),
            }
        )
    );
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
    if ((data || {}).success) {
        yield put(
            addMultiAuthDetail({
                authData: data.authData,
            })
        );
        sendToast(`Update complete`);
    } else {
        sendErrorToast("Some problem ocurred");
    }
}

function* addApiKey({ apiKey, companyId, env }) {
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/add_api_key",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    apiKey,
                }),
            }
        )
    );

    sendToast("Added succesfully");

    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
}

function* errorAuditLogs({ companyId, env, description, metadata, event }) {
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/dashboard/error_audit_logs",
            {
                method: "POST",
                body: JSON.stringify({
                    description,
                    metadata,
                    event,
                    companyId,
                }),
            }
        )
    );
    yield put(
        addMultiAuthDetail({
            authData: data.authData,
        })
    );
}

function* addSlackInfo({
    env,
    companyId,
    deleteSlackWebhook,
    slackNotificationsInvite,
    slackNotificationsCandidateScores,
}) {
    NProgress && NProgress.start();
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/integrations/slack/edit-info", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                deleteSlackWebhook,
                slackNotificationsInvite,
                slackNotificationsCandidateScores,
            }),
        })
    );

    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
    sendToast("Changes made successfully");
}

function* addIntegrationInfo({
    integration,
    SFusername,
    SFpassword,
    SFdomain,
    companyId,
    SFcompanyId,
    leverAuthCode,
    env,
    ashbyAPIKey,
    enableAshbyIntegration,
    SRCompanyId,
    enableSRIntegration,
}) {
    let data = "";
    if (integration === "successFactors") {
        data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/add_success_factors_info",
                {
                    method: "POST",
                    body: JSON.stringify({
                        SFusername,
                        SFpassword,
                        SFdomain,
                        companyId,
                        SFcompanyId,
                    }),
                }
            )
        );
    } else if (integration === "lever") {
        data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/lever/update_authorized_code",
                {
                    method: "POST",
                    body: JSON.stringify({
                        companyId,
                        leverAuthCode,
                    }),
                }
            )
        );
    } else if (integration === "ashby") {
        data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/add_ashby_info",
                {
                    method: "POST",
                    body: JSON.stringify({
                        companyId,
                        ashbyAPIKey,
                        enableAshbyIntegration,
                    }),
                }
            )
        );
    } else if (integration === "SR") {
        data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/add_smart_recruiters_info",
                {
                    method: "POST",
                    body: JSON.stringify({
                        companyId,
                        SRCompanyId,
                        enableSRIntegration,
                    }),
                }
            )
        );
    }

    if ((data || {}).success) {
        if (integration === "SR") {
            window.location.replace(
                decodeURIComponent(getQueryStringValue("redirect"))
            );
        } else {
            window.history.pushState(
                {},
                "",
                "/app/dashboard/" + companyId + "/integrations"
            );
        }
        sendToast("Added succesfully");
    }

    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
}

function* resetLeverIntegration({ companyId, env }) {
    const data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/integrations/lever/reset_integration",
            {
                method: "POST",
                body: JSON.stringify({ companyId }),
            }
        )
    );

    if (data.success) {
        yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );
        sendToast("Lever integration reset successfully");
    } else {
        sendErrorToast("Error in resetting lever integration");
    }
}

function* testSuccessFactors({
    SFusername,
    SFpassword,
    SFdomain,
    companyId,
    SFcompanyId,
}) {
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/test_success_factors",
            {
                method: "POST",
                body: JSON.stringify({
                    SFusername,
                    SFpassword,
                    SFdomain,
                    companyId,
                    SFcompanyId,
                }),
            }
        )
    );

    if (data && data.success) {
        sendToast("Working perfectly! :D");
    } else {
        sendErrorToast("Error in setup. Please verify details again.");
    }
}

function* logoutUser({ emailId, companyId }) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(getAPIDomain() + "/app/api/login-system/logout", {
            method: "POST",
            body: JSON.stringify({
                emailId,
                companyId,
            }),
        })
    );

    NProgress && NProgress.done(true);
}

function* fetchOnboardingData({ emailId }) {
    NProgress && NProgress.inc();
    try {
        const data = yield call(() =>
            request(
                window.location.origin.indexOf("localhost") !== -1
                    ? "http://localhost:3210/logs/dashboard"
                    : window.location.origin.indexOf("tester.adaface.com") !==
                        -1
                      ? "https://test-tasks.adaface.com/logs/dashboard"
                      : "https://tasks.adaface.com/logs/dashboard",
                {
                    method: "POST",
                    body: JSON.stringify({
                        emailId,
                    }),
                    withCredentials: false,
                    credentials: "omit",
                }
            )
        );
        yield put(fetchOnboardingDataSuccess({ data }));
    } catch (e) {
        console.log(e);
    }

    NProgress && NProgress.done(true);
}

function* getSubscriptionInvoices({ companyId }) {
    NProgress && NProgress.inc();
    const data = yield call(() =>
        request(
            getAPIDomain() +
                "/app/api/integrations/dashboard/get-invoices-and-subscription",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                }),
            }
        )
    );

    if (data.success) {
        yield put(
            getSubscriptionInvoicesSuccess({
                subscription: data.subscription,
                invoices: data.invoices,
            })
        );
    }
    NProgress && NProgress.done(true);
}

function* deleteCardDetails({ env, companyId }) {
    let data = yield call(() =>
        request(getAPIDomain() + "/app/api/user/stripe/delete_card_details", {
            method: "POST",
            body: JSON.stringify({
                companyId,
            }),
        })
    );
    const { success, error } = data;
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
    if (success) {
        sendToast("Card details removed successfully");
    } else {
        sendErrorToast(
            error || "There is an error with deleting the card details"
        );
    }

    yield put(
        deleteCardDetailsSuccess({
            companyId,
            success,
        })
    );
}

function* updateBillingDetails({ companyId, billingDetails }) {
    let data = yield call(() =>
        request(
            getAPIDomain() + "/app/api/user/stripe/update_billing_details",
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    billingDetails,
                }),
            }
        )
    );
    const { success, error, updatedBillingDetails } = data;

    if (success) {
        sendToast("Billing details updated successfully");
    } else {
        sendErrorToast(
            error || "There is an error with updating the billing details"
        );
    }

    if (success && !error) {
        yield put(
            updateBillingDetailsSuccess({
                companyId,
                success,
                updatedBillingDetails,
            })
        );
    } else {
        yield put(updateBillingDetailsError());
    }
}

function* cancelSubscription({ env, companyId, planId, reasons, suggestions }) {
    let data = yield call(() =>
        request(getAPIDomain() + "/app/api/user/stripe/cancel_subscription", {
            method: "POST",
            body: JSON.stringify({
                companyId,
                planId,
                reasons,
                suggestions,
            }),
        })
    );
    const { success, error } = data;
    const rand = yield call(() =>
        fetchUserDetails({
            env,
            userId: companyId,
        })
    );
    if (success) {
        sendToast("Subscription cancelled successfully");
    } else {
        sendErrorToast(
            (error || "").startsWith("No such customer:")
                ? "Please update your card details to continue."
                : error || "There is an error with cancelling the subscription"
        );
    }

    yield put(
        cancelSubscriptionSuccess({
            companyId,
            success,
        })
    );
}

function* previewAssessment({
    env,
    testId,
    companyId,
    requesterEmailId,
    requesterName,
}) {
    const {
        success,
        error,
        newTestId,
        questions,
        dtreesDetails,
        questionsDetails,
    } = yield call(() =>
        request(getAPIDomain() + "/app/api/company/preview-test/create", {
            method: "POST",
            body: JSON.stringify({
                testId,
                companyId,
                requesterEmailId,
                requesterName,
            }),
        })
    );
    if (success) {
        const rand = yield call(() =>
            fetchUserDetails({
                env,
                userId: companyId,
            })
        );

        const alert = yield call(() =>
            sendDiscordMessage({
                message: `Created preview test: ${JSON.stringify(
                    {
                        env,
                        baseTestId: testId,
                        companyId,
                        requesterEmailId,
                        requesterName,
                        testId: newTestId,
                    },
                    null,
                    4
                )}`,
                channel: "logs-preview-test",
            })
        );

        yield put(
            previewAssessmentSuccess({
                testId: newTestId,
                status: "success",
                newPreviewTest: { [newTestId]: { questions, dtreesDetails } },
                questionsDetails,
            })
        );

        if (typeof gtag !== "undefined" && gtag) {
            gtag("event", "ab_split_test", {
                test_event: "preview_test_created",
            });
        }
    } else {
        sendErrorToast(
            error || "Error creating test preview. Please try again."
        );
        yield put(previewAssessmentSuccess({ status: "failed" }));
    }
}

function* getPreviewTest({
    env,
    testId,
    companyId,
    mcqCategories,
    codingCategories,
    infoCategories,
}) {
    NProgress && NProgress.start();
    const {
        success,
        error,
        questions,
        dtreesDetails,
        questionsDetails,
        accountDeactivated,
    } = yield call(() =>
        request(getAPIDomain() + "/app/api/company/preview-test/fetch", {
            method: "POST",
            body: JSON.stringify({
                testId,
                companyId,
                mcqCategories,
                codingCategories,
                infoCategories,
            }),
        })
    );
    if (success) {
        yield put(
            getPreviewTestSuccess({
                testId,
                status: "success",
                previewTest: { [testId]: { questions, dtreesDetails } },
                companyId,
                questionsDetails,
            })
        );
    } else if (accountDeactivated) {
        yield put(
            getPreviewTestSuccess({
                testId,
                status: "success",
                previewTest: {},
                companyId,
                questionsDetails: {},
            })
        );
    } else {
        sendErrorToast(
            error || "Error fetching test preview. Please try again."
        );
        yield put(getPreviewTestSuccess({ status: "failed" }));
    }

    NProgress && NProgress.done();
}

function* fetchAddress({ companyId }) {
    NProgress && NProgress.start();
    const { success, error, address } = yield call(() =>
        request(getAPIDomain() + "/app/api/user/stripe/fetch-address", {
            method: "POST",
            body: JSON.stringify({
                companyId,
            }),
        })
    );
    if (success) {
        yield put(
            fetchAddressSuccess({
                status: "success",
                companyId,
                address,
            })
        );
    } else {
        // TODO: (SG) no point showing this error to customer for now
        // if (error !== "Unauthorized")
        //  sendErrorToast(error || "Error fetching address details");
        yield put(fetchAddressSuccess({ status: "failed" }));
    }

    NProgress && NProgress.done();
}

function* fetchLiveUpdate({}) {
    NProgress && NProgress.start();

    let pendingRequests = yield call(() =>
        request(getAPIDomain() + "/app/api/admin/pending-requests", {
            method: "POST",
            body: JSON.stringify({}),
        })
    );

    let testCounts = yield call(() =>
        request(getAPIDomain() + "/app/api/admin/test-counts", {
            method: "POST",
            body: JSON.stringify({}),
        })
    );

    let subscriptionCount = yield call(() =>
        request(getAPIDomain() + "/app/api/admin/subscription-counts", {
            method: "POST",
            body: JSON.stringify({}),
        })
    );

    yield put(
        fetchLiveUpdateSuccess({
            status: "success",
            liveUpdates: { pendingRequests, testCounts, subscriptionCount },
        })
    );
    NProgress && NProgress.done();
}

function* addOrUpdateListmonk({ name, email, attributes }) {
    yield call(() =>
        request(getAPIDomain() + "/app/api/update_listmonk", {
            method: "POST",
            body: JSON.stringify({
                name,
                email,
                attributes,
            }),
        })
    );
}

function* fetchProblems({ companyId }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(getAPIDomain() + `/app/api/problems?companyId=${companyId}`, {
            method: "GET",
        })
    );

    yield put(
        fetchProblemsSuccess({
            status: "success",
            problems: ((data || {}).data || {}).problems,
        })
    );
    NProgress && NProgress.done();
}

function* saveProblem({ id, name, code, description, testcases, companyId }) {
    NProgress && NProgress.start();

    try {
        const { success, error } = yield call(() =>
            request(getAPIDomain() + "/app/api/problems", {
                method: "POST",
                body: JSON.stringify({
                    id,
                    name,
                    code,
                    description,
                    testcases,
                    companyId,
                }),
            })
        );

        if (!error) {
            yield put(saveProblemSuccess({ success }));
        } else {
            yield put(saveProblemFailure({ success, error }));
        }
    } catch (err) {
        yield put(saveProblemFailure({ err }));
    }
    NProgress && NProgress.done();
}

function* fetchSubmissions({ limit, page, companyId }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(
            getAPIDomain() +
                `/app/api/submissions?companyId=${companyId}&${
                    limit > 0 ? `limit=${limit}` : ""
                }${limit > 0 && page > 0 ? "&" : ""}${
                    page > 0 ? `page=${page}` : ""
                }`,
            {
                method: "GET",
            }
        )
    );

    yield put(
        fetchSubmissionsSuccess({
            status: "success",
            submissions: ((data || {}).data || {}).submissions,
            submissionCount: ((data || {}).data || {}).submissionCount,
        })
    );
    NProgress && NProgress.done();
}

function* updateQuestionField({ questionId, isMetadata, field, value }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(getAPIDomain() + `/app/api/admin/update-question-field`, {
            method: "POST",
            body: JSON.stringify({
                questionId,
                isMetadata,
                field,
                value,
            }),
        })
    );

    yield put(
        updateQuestionFieldStatus({
            status: "success",
            questionId,
            isMetadata,
            field,
            value,
        })
    );
    NProgress && NProgress.done();
}

function* fetchNotificationFeed({ companyId, nextPageNotificationIndex }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(
            `https://api.knock.app/v1/users/${companyId}/feeds/${process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID}?after=${nextPageNotificationIndex}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_KNOCK_PUBLIC_API_KEY}`,
                },
                withCredentials: false,
                credentials: "omit",
            }
        )
    );

    yield put(
        fetchNotificationFeedStatus({
            status: "success",
            companyId,
            notificationFeed: data,
        })
    );
    NProgress && NProgress.done();
}

function* getSelectReviews({ companyId }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(
            getAPIDomain() + `/app/api/integrations/dashboard/one-shot-info`,
            {
                method: "POST",
                body: JSON.stringify({
                    companyId,
                }),
            }
        )
    );

    if (data.reviews) {
        data.reviews = data.reviews.map((x) => {
            try {
                x.questionAndAnswers = JSON.parse(x.questionAndAnswers);
            } catch (e) {}
            x.questionAndAnswers = x.questionAndAnswers.filter(
                (y) => y.question.indexOf("suggestions") === -1
            );
            return x;
        });
        data.reviews = data.reviews.filter(
            (x) => x.questionAndAnswers.length > 0
        );
        data.reviews.map((x) => {
            x.stars = `${x.rating === 5 ? "⭐⭐⭐⭐⭐" : "⭐⭐⭐⭐"}`;
            x.keyComment = `${x.questionAndAnswers[0].answer}`;
            return x;
        });
    }

    yield put(
        getSelectReviewsSuccess({
            status: "success",
            companyId,
            reviews: data.reviews || [],
            oneShotData: data.oneShotData || {},
        })
    );
    NProgress && NProgress.done();
}

function* updateFAQs({ companyId, faqs, enable }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(getAPIDomain() + `/app/api/company/faqs/`, {
            method: "POST",
            body: JSON.stringify({
                companyId,
                faqs,
                enable,
            }),
        })
    );

    yield put(
        updateFAQsStatus({
            status: data.success ? "success" : "failure",
            companyId,
            faqs,
            enable,
            error: data.error,
        })
    );
    NProgress && NProgress.done();
}

function* fetchAdaAIContacts({ companyId }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(`${parserDomain}/ai/contact/`, {
            method: "POST",
            body: JSON.stringify({
                companyId,
            }),
        })
    );

    yield put(
        fetchAdaAIContactsStatus({
            status:
                !data.error && (data || {}).contacts.length > 0
                    ? "success"
                    : "failure",
            contacts: (data || {}).contacts || [],
        })
    );
    NProgress && NProgress.done();
}

function* fetchAdaAIContactDocuments({ companyId, contactId }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(`${parserDomain}/ai/contact/documents`, {
            method: "POST",
            body: JSON.stringify({
                companyId,
                contactId,
            }),
        })
    );

    if ((data || {}).error) {
        sendErrorToast("Error fetching resumes");
    }
    yield put(
        fetchAdaAIContactDocumentsStatus({
            status:
                !(data || {}).error && ((data || {}).documents || []).length > 0
                    ? "success"
                    : "failure",
            documents: ((data || {}).documents || []).map((document) => ({
                ...document,
                createdtime: new Date(document.createdtime).getTime(),
            })),
            contactId,
        })
    );
    NProgress && NProgress.done();
}

function* fetchAdaAIContactSession({
    companyId,
    userId,
    contactId,
    clearSession,
    isMultiContact,
}) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(`${parserDomain}/ai/session/`, {
            method: "POST",
            body: JSON.stringify({
                companyId,
                contactIds: isMultiContact ? null : [contactId],
                userId,
                newSession: clearSession,
                keepChat: !clearSession,
                type: isMultiContact ? "multi" : "",
            }),
        })
    );

    if ((data || {}).error) {
        sendErrorToast("Error fetching session");
    }

    yield put(
        fetchAdaAIContactSessionsStatus({
            status: (data || {}).session ? "success" : "failure",
            session: (data || {}).session,
            contactId,
            isMultiContact:
                isMultiContact || (contactId || "").split(",").length > 1,
            clearSession,
        })
    );
    NProgress && NProgress.done();
}

function* createAdaAIQuery({ query, sessionId, queryType }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(`${parserDomain}/ai/query/${queryType ? "multi" : ""}`, {
            method: "POST",
            body: JSON.stringify({
                sessionId,
                query,
                queryType,
            }),
        })
    );

    yield put(
        createAdaAIQueryStatus({
            status: (data || {}).queryId ? "in-progress" : "failed",
            queryId: (data || {}).queryId,
            queryType: (data || {}).querytype,
            query,
            sessionId,
            currentLog: "Analyzing your query…",
        })
    );

    if ((data || {}).error) {
        sendErrorToast("Error while processing query");
    }

    if ((data || {}).queryId) {
        let queryStatus = yield call(() =>
            request(`${parserDomain}/ai/query/${(data || {}).queryId}`, {
                method: "GET",
            })
        );

        while (
            !(queryStatus || {}).error &&
            ((queryStatus || {}).query || {}).status !== "success" &&
            ((queryStatus || {}).query || {}).status !== "done"
        ) {
            yield delay(2000);

            queryStatus = yield call(() =>
                request(`${parserDomain}/ai/query/${(data || {}).queryId}`, {
                    method: "GET",
                })
            );

            if (!(queryStatus || {}).error) {
                const updateStatus = yield put(
                    createAdaAIQueryStatus({
                        status: ((queryStatus || {}).query || {}).status,
                        queryId: ((queryStatus || {}).query || {}).queryid,
                        queryType: ((queryStatus || {}).query || {}).querytype,
                        query,
                        response: ((queryStatus || {}).query || {}).response,
                        sessionId,
                        currentLog:
                            ((queryStatus || {}).query || {}).status !==
                            "success"
                                ? ((queryStatus || {}).query || {}).logs
                                    ? (queryStatus || {}).query.logs[
                                          (queryStatus || {}).query.logs
                                              .length - 1
                                      ]
                                    : "Analyzing your query…"
                                : "Completed",
                    })
                );
            }
        }

        if ((queryStatus || {}).error) {
            sendErrorToast("Error while processing query");
            yield put(
                createAdaAIQueryStatus({
                    status: "failed",
                    query,
                    sessionId,
                })
            );
        }
    } else {
        yield put(
            createAdaAIQueryStatus({
                status: "failed",
                query,
                sessionId,
            })
        );
    }
    NProgress && NProgress.done();
}

function* upsertDocuments({ companyId, userId, documentUrls }) {
    NProgress && NProgress.start();

    let data = yield call(() =>
        request(`${parserDomain}/ai/document/upsert`, {
            method: "POST",
            body: JSON.stringify({
                urls: documentUrls,
                companyId,
                userId,
            }),
        })
    );
    if ((data || {}).success && (data || {}).sessionId) {
        let sessionUpdate = yield call(() =>
            request(`${parserDomain}/ai/session/${data.sessionId}/status`, {
                method: "GET",
            })
        );

        yield call(() =>
            fetchAdaAIContactSession({
                companyId,
                userId,
                isMultiContact: true,
            })
        );

        let fetchedContactDetails = [];

        while (sessionUpdate.status !== "done") {
            yield delay(2000);

            sessionUpdate = yield call(() =>
                request(`${parserDomain}/ai/session/${data.sessionId}/status`, {
                    method: "GET",
                })
            );

            if (sessionUpdate.error || sessionUpdate.status === "failed") {
                yield put(
                    upsertDocumentsStatus({
                        status: "failed",
                        companyId,
                        documentUrls,
                    })
                );
            } else if (sessionUpdate.status === "done") {
                const processingStatus =
                    Object.values(sessionUpdate.processingstatus || {}) || [];
                let readableStatus = "";
                for (var i = 0; i < (processingStatus || []).length; i++) {
                    readableStatus =
                        readableStatus +
                        (readableStatus ? "\n" : "") +
                        (processingStatus[i].status !== "done"
                            ? `${
                                  documentUrls.length > 1
                                      ? `File ${i + 1}:`
                                      : ""
                              } ${
                                  processingStatus[i].status === "failed"
                                      ? processingStatus[i].message ||
                                        "Error while processing file"
                                      : processingStatus[i].status
                              }`
                            : "");
                    yield call(() =>
                        fetchAdaAIContactSession({
                            companyId,
                            userId,
                            contactId: processingStatus[i].contactId,
                        })
                    );
                }

                yield call(() =>
                    fetchAdaAIContacts({
                        companyId,
                    })
                );

                yield call(() =>
                    fetchAdaAIContactSession({
                        companyId,
                        userId,
                        isMultiContact: true,
                    })
                );
                yield put(
                    upsertDocumentsStatus({
                        companyId,
                        status: sessionUpdate.status,
                        readableStatus,
                        documents: documentUrls,
                        sessionId: data.sessionId,
                        processingStatus: sessionUpdate.processingstatus,
                    })
                );
            } else {
                const processingStatus =
                    Object.values(sessionUpdate.processingstatus || {}) || [];

                let readableStatus = "";
                for (var i = 0; i < (processingStatus || []).length; i++) {
                    readableStatus =
                        readableStatus +
                        (readableStatus ? "\n" : "") +
                        (processingStatus[i].status !== "done"
                            ? `${
                                  documentUrls.length > 1
                                      ? `File ${i + 1}:`
                                      : ""
                              } ${
                                  processingStatus[i].status === "failed"
                                      ? processingStatus[i].message ||
                                        "Error while processing file"
                                      : processingStatus[i].status
                              }`
                            : "");
                    if (
                        processingStatus[i].contactId &&
                        !fetchedContactDetails.includes(
                            processingStatus[i].contactId
                        )
                    ) {
                        fetchedContactDetails.push(
                            processingStatus[i].contactId
                        );
                        yield all([
                            call(() =>
                                fetchAdaAIContacts({
                                    companyId,
                                })
                            ),
                            call(() =>
                                fetchAdaAIContactSession({
                                    companyId,
                                    userId,
                                    contactId: processingStatus[i].contactId,
                                })
                            ),
                            call(() =>
                                fetchAdaAIContactDocuments({
                                    companyId,
                                    contactId: processingStatus[i].contactId,
                                })
                            ),
                        ]);
                    }
                }
                yield put(
                    upsertDocumentsStatus({
                        status: sessionUpdate.status,
                        readableStatus,
                        companyId,
                        documents: documentUrls,
                        sessionId: data.sessionId,
                        processingStatus: sessionUpdate.processingstatus,
                    })
                );
            }
        }
    }
}

function* getBlockedEmails() {
    NProgress && NProgress.start();

    try {
        let data = yield call(() =>
            request(getAPIDomain() + `/app/api/admin/blocked-emails`, {
                method: "GET",
            })
        );

        yield put(
            getBlockedEmailsSuccess({
                status: "success",
                blockedEmails: data || [],
            })
        );
    } catch (e) {
        sendErrorToast("Error fetching blocked emails");
    }
    NProgress && NProgress.done();
}

function* deleteBlockedEmail({ email }) {
    NProgress && NProgress.start();

    try {
        let data = yield call(() =>
            request(getAPIDomain() + `/app/api/admin/blocked-emails`, {
                method: "DELETE",
                body: JSON.stringify({
                    email,
                }),
            })
        );

        if (data?.success) {
            yield put(
                deleteBlockedEmailSuccess({
                    status: "success",
                    email,
                })
            );
            sendToast("Email deleted from blocked list");
        } else {
            sendErrorToast("Error deleting email from blocked list");
        }
    } catch (e) {
        sendErrorToast("Error deleting email from blocked list");
    }

    NProgress && NProgress.done();
}

function* sendCustomTestReadyEmail({
    name,
    email,
    comment,
    testName,
    testLink,
}) {
    NProgress && NProgress.start();

    try {
        yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/admin/custom_test_ready",
                {
                    method: "POST",
                    body: JSON.stringify({
                        name,
                        email,
                        comment,
                        testName,
                        testLink,
                    }),
                }
            )
        );
        sendToast("Email sent successfully");
    } catch (e) {
        sendErrorToast("Error sending email");
    }

    NProgress && NProgress.done();
}

function* extendInterviewDeadline({ interviewId, companyId, updatedDeadline }) {
    NProgress && NProgress.start();

    try {
        const data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/intergrations/dashbaord/extend_interview_deadline",
                {
                    method: "POST",
                    body: JSON.stringify({
                        interviewId,
                        companyId,
                        updatedDeadline,
                        recruiterTimeZone: moment.tz.guess(),
                    }),
                }
            )
        );

        if (data?.success) {
            // Refresh interview details
            yield call(() => getInterviewDetails({ interviewId, companyId }));

            sendToast("Interview deadline extended successfully.");

            yield put(
                extendInterviewDeadlineSuccess({
                    interviewId,
                    updatedDeadline,
                })
            );
        } else {
            sendErrorToast(
                data?.error || "Failed to extend interview deadline."
            );

            yield put(
                extendInterviewDeadlineFailure({
                    error:
                        data?.error || "Failed to extend interview deadline.",
                })
            );
        }
    } catch (error) {
        sendErrorToast(
            "An error occurred while extending the interview deadline."
        );

        yield put(extendInterviewDeadlineFailure({ error }));
    }

    NProgress && NProgress.done();
}

function* editEmailTemplate({
    env,
    companyId,
    templateName,
    htmlTemplate,
    subjectWithoutCandidateName,
    subjectWithCandidateName,
    htmlString,
    subjectString,
    testId,
    emailType,
}) {
    NProgress && NProgress.start();

    yield put(editEmailTemplateStatus({ status: "in-progress" }));

    try {
        const data = yield call(() =>
            request(
                getAPIDomain() +
                    "/app/api/integrations/dashboard/edit-test-email-template",
                {
                    method: "POST",
                    body: JSON.stringify({
                        companyId,
                        templateName,
                        htmlTemplate,
                        subjectWithoutCandidateName,
                        subjectWithCandidateName,
                        htmlString,
                        subjectString,
                        testId,
                        type: emailType,
                    }),
                }
            )
        );

        if (data?.success) {
            const rand = yield call(() =>
                fetchUserDetails({
                    env,
                    userId: companyId,
                })
            );

            yield put(editEmailTemplateStatus({ status: "success" }));
            sendToast("Email template updated successfully");
        } else {
            yield put(editEmailTemplateStatus({ status: "failure" }));
            sendErrorToast("Error updating email template");
        }
    } catch (e) {
        sendErrorToast("Error editing email template");
    }
    NProgress && NProgress.done();
}

function* archiveQuestion({ questionId, companyId }) {
    NProgress && NProgress.start();

    yield put(archiveQuestionStatus({ status: "in-progress" }));

    try {
        const data = yield call(() =>
            request(
                getAPIDomain() +
                    `/app/api/integrations/dashboard/archive_question`,
                {
                    method: "POST",
                    body: JSON.stringify({ questionId, companyId }),
                }
            )
        );

        if (data?.success) {
            sendToast("Custom question deleted");
            yield put(archiveQuestionStatus({ status: "success" }));
        } else {
            sendErrorToast("Error deleting custom question");
            yield put(archiveQuestionStatus({ status: "failure" }));
        }
    } catch (error) {
        sendErrorToast("Error deleting custom question");
        yield put(archiveQuestionStatus({ status: "failure" }));
    }

    NProgress && NProgress.done();
}

function* saveQuestionsToTestFromLibrary({ companyId, testId, questionIds }) {
    NProgress && NProgress.start();

    try {
        yield put(
            saveQuestionsToTestFromLibraryStatus({ status: "in-progress" })
        );

        const data = yield call(() =>
            request(
                getAPIDomain() +
                    `/app/api/integrations/dashboard/save_questions_to_test_from_library`,
                {
                    method: "POST",
                    body: JSON.stringify({ companyId, testId, questionIds }),
                }
            )
        );

        if (data?.success) {
            yield put(
                addQuestionsToTestResult({
                    companyId,
                    testId,
                    success: data?.success,
                    error: "",
                    newQuestions: data?.questions || {},
                    updatedTest: data?.test || {},
                })
            );

            yield put(
                saveQuestionsToTestFromLibraryStatus({ status: "success" })
            );

            sendToast("Questions saved to test from library");
        } else {
            sendErrorToast("Error saving questions to test from library");
        }
    } catch (error) {
        yield put(saveQuestionsToTestFromLibraryStatus({ status: "failure" }));
        sendErrorToast("Error saving questions to test from library");
    }

    NProgress && NProgress.done();
}

export default function* rootSaga() {
    yield takeEvery(GET_USER_DETAILS, fetchUserDetails);
    yield takeEvery(EDIT_USERS, editUsers);
    yield takeEvery(EDIT_TEAM_MEMBER, editTeamMember);
    yield takeEvery(RESEND_TEAM_INVITE, resendTeamInvite);
    yield takeEvery(DELETE_USER, deleteUser);
    yield takeEvery(COMPLETELY_DELETE_USER, completelyDeleteUser);
    yield takeEvery(DEACTIVATE_USER, deactivateUser);
    yield takeEvery(CHANGE_PERMISSION, changePermissions);
    yield takeEvery(EDIT_API_KEYS, addApiKey);
    yield takeEvery(UPLOAD_INTERVIEW_DATA, uploadInterviewData);
    yield takeEvery(GET_INTERVIEWS_DATA, getInterviewsData);
    yield takeEvery(GET_AUDIT_LOGS, getAuditLogs);
    yield takeEvery(ADD_COMPANY, addCompany);
    yield takeEvery(GET_QUESTIONS_DATA, getQuestionsData);
    yield takeEvery(GET_QUESTIONS_DATA_TEST, getQuestionsDataForTest);
    yield takeEvery(PUBLISH_UPDATE_TEST, publishUpdateTest);
    yield takeEvery(GET_SCORECARD_DATA, getScorecardData);
    yield takeEvery(GET_SCORECARD_IP_DETAILS, getScorecardIpDetails);
    yield takeEvery(GET_SCORECARD_INTERVIEW_DATA, getScorecardInterviewData);

    yield takeEvery(UPDATE_CARD, updateCard);
    yield takeEvery(SWITCH_PLANS, switchPlans);
    yield takeEvery(UPDATE_CREDITS_MANUALLY, updateCreditsManually);
    yield takeEvery(
        GET_INTERVIEWS_AND_QUESTIONS_DATA,
        getInterviewsAndQuestionsData
    );
    yield takeEvery(SEND_DISCORD_MESSAGE, sendDiscordMessage);
    yield takeEvery(TRIGGER_KNOCK_NOTIFICATION, triggerKnockNotification);
    yield takeEvery(UPDATE_TEST_METADATA, updateTestMetadata);
    yield takeEvery(UPDATE_TEST_SUBSCRIBERS, updateTestSubscribers);
    yield takeEvery(GENERATE_TEST_ONELINK, generateTestOnelink);
    yield takeEvery(TOGGLE_TEST_ONELINK, toggleTestOnelink);
    yield takeEvery(SEND_SHORTLIST_EMAIL, sendShortlistEmail);
    yield takeEvery(RESEND_CANDIDATE_INVITE_EMAIL, resendCandidateInviteEmail);
    yield takeEvery(SEND_REJECTION_EMAIL, sendRejectionEmail);
    yield takeEvery(SEND_REMINDER_EMAIL, sendReminderEmail);
    yield takeEvery(UPDATE_USER_ACTIVITY, updateUserActivity);
    yield takeEvery(COMPILE_CODE, compileCode);
    yield takeEvery(UPDATE_TEST_THRESHOLD, updateTestThreshold);
    yield takeEvery(UPDATE_TEST_TIMEBOXED, updateTestTimeboxed);
    yield takeEvery(UPDATE_TEST_PROCTORING_MODE, updateTestProctoringMode);
    yield takeEvery(REVIEW_INTERVIEW, reviewInterview);
    yield takeEvery(
        REMOVE_QUESTION_FROM_SCORECARD,
        removeQuestionFromScorecard
    );
    yield takeEvery(REVIEW_BULK_INTERVIEWS, reviewBulkInterviews);
    yield takeEvery(END_INTERVIEW, endInterview);
    yield takeEvery(DELETE_INTERVIEW, deleteInterview);
    yield takeEvery(CANCEL_INTERVIEW, cancelInterview);
    yield takeEvery(CANCEL_BULK_INTERVIEWS, cancelBulkInterviews);
    yield takeEvery(GET_TEST_LIBRARY, getTestLibrary);
    yield takeEvery(GET_ADDITIONAL_TESTS_DATA, getAdditionalTestsData);
    yield debounce(400, SEARCH_TEST_LIBRARY, searchTestLibrary);
    yield takeEvery(ADD_NEW_TEST_REQUEST, addNewTestRequest);
    yield takeEvery(ADD_UPDATE_EMAIL_TEMPLATE, addUpdateEmailTemplate);
    yield takeEvery(UPDATE_TEST_EMAIL_TEMPLATES, updateTestEmailTemplates);
    yield takeEvery(GET_INSIGHTS, getInsights);
    yield takeEvery(GET_CANDIDATE_FUNNEL, getCandidateFunnel);
    yield takeEvery(GET_SCORE_DISTRIBUTION, getScoreDistribution);
    yield takeEvery(GET_DAYS_DATA, getDaysData);
    yield takeEvery(GET_INVITED_BY, getInvitedBy);
    yield takeEvery(GET_AVG_COMPLETION_TIME, getAvgCompletionTime);
    yield takeEvery(GET_AVG_RATINGS, getAvgRatings);
    yield takeEvery(GET_REVIEWS, getReviews);
    yield takeEvery(ADD_API_KEY, addApiKey);
    yield takeEvery(TEST_SUCCESS_FACTORS, testSuccessFactors);
    yield takeEvery(ADD_INTEGRATION_INFO, addIntegrationInfo);
    yield takeEvery(ERROR_AUDIT_LOGS, errorAuditLogs);
    yield takeEvery(REMOVE_SAMPLE_CANDIDATES, removeSampleCandidates);
    yield takeEvery(ADD_SLACK_INFO, addSlackInfo);
    yield takeEvery(LOGOUT_USER, logoutUser);
    yield takeEvery(GET_SUBSCRIPTION_INVOICES, getSubscriptionInvoices);
    yield takeEvery(GET_PAIRS_PAGINATED, getPairsData);
    yield takeEvery(CREATE_PAIR, createPair);
    yield takeEvery(SEND_PAIR_INVITE_EMAIL, sendPairInviteEmail);
    yield takeEvery(GET_INTERVIEW_QUESTIONS, getInterviewQuestions);
    yield takeEvery(CREATE_INTERVIEW_QUESTION, createInterviewQuestion);
    yield takeEvery(ARCHIVE_INTERVIEW_QUESTION, archiveInterviewQuestion);
    yield takeEvery(GET_PUBLIC_QUESTIONS, getPublicQuestions);
    yield takeEvery(
        REGISTER_CANDIDATE_PAIR_DETAILS,
        registerCandidatePairDetails
    );
    yield takeEvery(CREATE_READY_TEST, createReadyTest);
    yield takeEvery(FETCH_ONBOARDING_DATA, fetchOnboardingData);
    yield takeEvery(UPDATE_TEST_NAME, updateTestName);
    yield takeEvery(SEND_BULK_INVITES, sendBulkInvites);
    yield takeEvery(EXPORT_GRAPH_FETCH, exportGraphFetch);
    yield takeEvery(ADD_QUESTIONS_TO_TEST, addQuestionsToTest);
    yield takeEvery(DELETE_QUESTION_TEST, deleteQuestionFromTest);
    yield takeEvery(VERIFY_QUESTION, verifyQuestion);
    yield takeEvery(SORT_QUESTIONS_DND, sortQuestionsDnd);

    yield takeEvery(FETCH_RTG_DATA, fetchRTGData);
    yield takeEvery(FETCH_SAAS_PLANS, fetchSaasPlans);
    yield takeEvery(GET_TEST_METADATA, getTestMetadata);

    yield takeEvery(DELETE_CARD_DETAILS, deleteCardDetails);
    yield takeEvery(UPDATE_BILLING_DETAILS, updateBillingDetails);
    yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
    yield takeEvery(DELETE_TEST, deleteTest);

    yield takeEvery(GET_SHALLOW_INTERVIEWS, getShallowInterviewsData);
    yield takeEvery(
        GET_HIGH_LEVEL_INTERVIEWS_TOTAL,
        getHighLevelInterviewsTotal
    );
    yield takeEvery(GET_INTERVIEW_DETAILS, getInterviewDetails);
    yield takeEvery(PREVIEW_ASSESSMENT, previewAssessment);
    yield takeEvery(GET_PREVIEW_TEST, getPreviewTest);
    yield takeEvery(GET_SCORE_TIME_SERIES, getScoreTimeSeries);
    yield takeEvery(FETCH_ADDRESS, fetchAddress);
    yield takeEvery(FETCH_LIVE_UPDATES, fetchLiveUpdate);
    yield takeEvery(FETCH_PROBLEMS, fetchProblems);
    yield takeEvery(SAVE_PROBLEM, saveProblem);
    yield takeEvery(FETCH_SUBMISSIONS, fetchSubmissions);
    yield takeEvery(UPDATE_QUESTION_FIELD, updateQuestionField);
    yield takeEvery(
        SEND_BULK_RESULT_EMAIL_TO_CANDIDATE,
        sendBulkResultEmailToCandidate
    );
    yield takeEvery(FETCH_NOTIFICATION_FEED, fetchNotificationFeed);
    yield takeEvery(UPDATE_FAQS, updateFAQs);
    yield takeEvery(FETCH_ADA_AI_CONTACTS, fetchAdaAIContacts);
    yield takeEvery(FETCH_ADA_AI_CONTACT_DOCUMENTS, fetchAdaAIContactDocuments);
    yield takeEvery(FETCH_ADA_AI_CONTACT_SESSIONS, fetchAdaAIContactSession);
    yield takeEvery(CREATE_ADA_AI_QUERY, createAdaAIQuery);
    yield takeEvery(UPSERT_DOCUMENTS, upsertDocuments);
    yield takeEvery(ADD_OR_UPDATE_LISTMONK, addOrUpdateListmonk);
    yield takeEvery(GET_ALL_CATEGORIES, getAllCategories);
    yield takeEvery(GET_SELECT_REVIEWS, getSelectReviews);
    yield takeEvery(GET_BLOCKED_EMAILS, getBlockedEmails);
    yield takeEvery(DELETE_BLOCKED_EMAIL, deleteBlockedEmail);
    yield takeEvery(SEND_CUSTOM_TEST_READY_EMAIL, sendCustomTestReadyEmail);
    yield takeEvery(EXTEND_INTERVIEW_DEADLINE, extendInterviewDeadline);
    yield takeEvery(CLONE_TEST, cloneTest);
    yield takeEvery(CREATE_BLANK_TEST, createBlankTest);
    yield takeEvery(GET_QUESTION_LIBRARY, getQuestionLibrary);
    yield takeEvery(ARCHIVE_QUESTION, archiveQuestion);
    yield takeEvery(EDIT_EMAIL_TEMPLATE, editEmailTemplate);
    yield takeEvery(RESET_LEVER_INTEGRATION, resetLeverIntegration);
    yield takeEvery(
        SAVE_QUESTIONS_TO_TEST_FROM_LIBRARY,
        saveQuestionsToTestFromLibrary
    );
}
