/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Flow from "../../containers/Flow/Loadable";
import PairSession from "../../containers/PairSession/Loadable";
import PairLandingPage from "../../containers/PairLandingPage/Loadable";
import NotFoundPage from "../../containers/NotFoundPage/Loadable";
import Dashboard from "../../containers/Dashboard/Loadable";
import Scorecard from "../Scorecard/Loadable";
import PublicScorecard from "../PublicScorecard/Loadable";
import ChatStart from "../../containers/ChatStart/Loadable";
import ChatEnd from "../../containers/ChatEnd/Loadable";
import InterviewStatusPage from "../../containers/InterviewStatusPage/Loadable";
import DashboardLoginPage from "../DashboardLoginPage/Loadable";
import AccountPage from "../Account/Loadable";
import Auth from "../../utils/Auth.js";
import IP from "../../utils/IP.js";
import Proctoring from "../../utils/Proctoring.js";
import FullscreenProctoring from "../../utils/FullscreenProctoring.js";
import ScreenShareProctoring from "../../utils/ScreenShareProctoring.js";
import CandidateLoginPage from "../../containers/CandidateLoginPage";
import { withCookies, Cookies } from "react-cookie";

import "react-toastify/dist/ReactToastify.css";
import ExternalRedirect from "../../components/ExternalRedirect/index.js";

const auth = new Auth(process.env.NODE_ENV);
const ip = new IP(process.env.NODE_ENV);
const proctoring = new Proctoring(process.env.NODE_ENV);
const fullscreenProctoring = new FullscreenProctoring(process.env.NODE_ENV);
const screenShareProctoring = new ScreenShareProctoring(process.env.NODE_ENV);

// TODO: ideally these need to be managed similar to auth instances but for now, attached to window
// singletons
window.socketIOclient = null;
window.chatSessionVariables = {};
window.firepadInstances = {};
window.codeVersion = process.env.CODE_VERSION;

// var socketIOclient;
// var chatSessionVariables = {};
// var codeMirrorInstances = {};
// var codeMirrorLanguageInstances = {};
// var firepadInstances = {};

// below variables used for single chat session instance instead of group
// var adafaceAlertSent = false;
// var messagesBufferManagerInit = false;
// var messagesBuffer = [];
// var typingPulse = [];
// var typingPulseManagerInit = false;
// var typingMessagesPulse = [];

window.adafaceAlertSent = false;
window.messagesBufferManagerInit = false;
window.messagesBufferManagerWait = 0;
window.messagesBuffer = [];
window.typingPulse = [];
window.typingPulseManagerInit = false;
window.typingMessagesPulse = [];
window.codeMirrorInstances = {};
window.codeMirrorLanguageInstances = {};
window.codeMirrorProblemIdInstances = {};

// function getNewChatSessionVariables() {
//   return {
//     typingMessagesPulse: [],
//     typingPulseManagerInit: false,
//     typingPulse: [],
//     messagesBuffer: [],
//     messagesBufferManagerInit: false,
//     adafaceAlertSent: false,
//   }
// }

window.getNewChatSessionVariables = function () {
    return {
        typingMessagesPulse: [],
        typingPulseManagerInit: false,
        typingPulse: [],
        messagesBuffer: [],
        messagesBufferManagerInit: false,
        messagesBufferManagerWait: 0,
        adafaceAlertSent: false,
        codeMirrorInstances: {},
        codeMirrorLanguageInstances: {},
        codeMirrorProblemIdInstances: {},
    };
};

// console.log("proctoring created", proctoring);

export class App extends React.PureComponent {
    render() {
        return (
            <div className="react-dom-container">
                <BrowserRouter>
                    <Switch>
                        {/* Error pages */}
                        <Route
                            path="/app/error/:errorId"
                            render={(props) => <NotFoundPage {...props} />}
                        />
                        {/* Success pages */}
                        <Route
                            path="/app/success/:errorId"
                            render={(props) => <NotFoundPage {...props} />}
                        />

                        {/* Common account page */}
                        <Route
                            path="/app/account"
                            render={(props) => (
                                <AccountPage {...props} auth={auth} />
                            )}
                        />

                        {/* Good JD tool */}
                        <Route
                            path="/app/good-jd-editor/:companyId/:roleId"
                            render={(props) => (
                                <Redirect
                                    to={`/app/dashboard/${props.match.params.companyId}`}
                                />
                            )}
                        />
                        <Route
                            path="/app/good-jd-editor"
                            render={(props) => (
                                <ExternalRedirect url="https://www.adaface.com/job-descriptions/" />
                            )}
                        />

                        {/* Dashboard for clients */}
                        <Route
                            path="/app/dashboard/login"
                            render={(props) => (
                                <DashboardLoginPage {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/signup"
                            render={(props) => (
                                <DashboardLoginPage
                                    {...props}
                                    auth={auth}
                                    signup={true}
                                    cookies={this.props.cookies}
                                />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/scorecard/:interviewId"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/candidates"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/candidate/:interviewId"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/assessments/request"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/library/:categoryId"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/assessments"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/assessment/:assessmentId"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/assessment/:assessmentId/settings"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/assessment/:assessmentId/questions"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/team"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/custom-questions"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/faqs"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/billing"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/careers"
                            render={(props) => (
                                <Redirect
                                    to={`/app/dashboard/${props.match.params.userId}`}
                                    {...props}
                                    auth={auth}
                                />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/pricing"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/pricing/subscription"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/pricing/invoices"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/pricing/payment-method"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/pair-pricing"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/insights"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/logs"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/emails"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/pair"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/dashboard/:userId/notifications"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />

                        <Route
                            path="/app/dashboard/:userId/ai/candidates"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />

                        <Route
                            path="/app/dashboard/:userId/ai/candidate/:contactId"
                            render={(props) => (
                                <Dashboard {...props} auth={auth} />
                            )}
                        />

                        {/* Pair sessions */}
                        <Route
                            path="/app/:interviewId/pair/interviewer"
                            render={(props) => (
                                <PairSession
                                    ip={ip}
                                    auth={auth}
                                    proctoring={proctoring}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/app/:interviewId/pair"
                            render={(props) => (
                                <PairSession
                                    ip={ip}
                                    auth={auth}
                                    proctoring={proctoring}
                                    {...props}
                                />
                            )}
                        />
                        <Route
                            path="/app/:interviewId/start-interview"
                            component={PairLandingPage}
                        />
                        <Route
                            path="/app/chat/:interviewId/:agentId"
                            render={(props) => (
                                <Flow
                                    {...props}
                                    auth={auth}
                                    proctoring={proctoring}
                                    fullscreenProctoring={fullscreenProctoring}
                                    screenShareProctoring={
                                        screenShareProctoring
                                    }
                                    ip={ip}
                                />
                            )}
                        />
                        <Route
                            path="/app/chat/:interviewId"
                            render={(props) => (
                                <Flow
                                    {...props}
                                    auth={auth}
                                    proctoring={proctoring}
                                    fullscreenProctoring={fullscreenProctoring}
                                    screenShareProctoring={
                                        screenShareProctoring
                                    }
                                    ip={ip}
                                />
                            )}
                        />
                        {/* Challenge pages hosted for clients */}
                        {/* <Route path="/app/challenge/:clientId/:roleId" render={(props) => <ChallengeLandingPage {...props} auth={auth} />} /> */}
                        {/* Certificatge landing pages */}
                        {/* <Route path="/app/certification/:certificateId" render={(props) => <ChallengeLandingPage {...props} auth={auth} />} /> */}
                        {/* <Route path="/app/js-demo" render={(props) => <ChallengeLandingPage {...props} auth={auth} />} /> */}

                        <Route
                            path="/app/:interviewId/chat/status/:status"
                            render={(props) => (
                                <InterviewStatusPage {...props} />
                            )}
                        />

                        {/* Public scorecard mode */}
                        <Route
                            path="/app/scorecard/public/preview/:testId"
                            render={(props) => (
                                <PublicScorecard
                                    {...props}
                                    isPreviewURL={true}
                                    auth={auth}
                                />
                            )}
                        />
                        <Route
                            path="/app/scorecard/public/:testId"
                            render={(props) => (
                                <PublicScorecard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/scorecard/:interviewId/:viewKey/forExport/:forExport/pageBreak/:pageBreak"
                            render={(props) => (
                                <Scorecard {...props} auth={auth} />
                            )}
                        />
                        <Route
                            path="/app/scorecard/:interviewId/:viewKey"
                            render={(props) => (
                                <Scorecard {...props} auth={auth} />
                            )}
                        />

                        {/* All candidate pages */}
                        <Route
                            path="/app/:interviewId/end"
                            render={(props) => <ChatEnd {...props} />}
                        />
                        <Route
                            path="/app/:interviewId/start"
                            render={(props) => <ChatStart {...props} />}
                        />
                        <Route
                            path="/app/candidate/login"
                            render={(props) => (
                                <CandidateLoginPage
                                    {...props}
                                    auth={auth}
                                    cookies={this.props.cookies}
                                    proctoring={proctoring}
                                />
                            )}
                        />
                        <Route path="" component={NotFoundPage} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

// export default App;
export default withCookies(App);
